<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat dark color="primary">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title v-if="creating">Criar Item</v-list-item-title>
            <v-list-item-title v-if="updating">Alterar Item</v-list-item-title>
            <v-list-item-subtitle>Listagem dos dados</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn @click="$router.back()" icon>
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-col>
    <template v-if="payload">
      <v-col cols="12">
        <v-card flat outlined>
          <v-card-text>
            <v-form ref="form" v-model="valid">
              <v-row dense>
                <v-col cols="12">
                  <v-text-field v-model="payload.name" label="Nome*" type="text"
                                :error-messages="formErrors.name"
                                hide-details="auto" :rules="[$rules.required]"/>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field v-model="payload.unity" label="Unidade" type="text"
                                :error-messages="formErrors.unity"
                                hide-details="auto"/>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field v-model="payload.sku" label="Código interno (SKU)" type="text"
                                :error-messages="formErrors.sku"
                                hide-details="auto"/>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field v-model="payload.barcode" label="Código de Barras" type="text"
                                :error-messages="formErrors.barcode"
                                hide-details="auto"/>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field v-model="payload.brand" label="Marca" type="text"
                                :error-messages="formErrors.brand"
                                hide-details="auto"/>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field v-model="payload.ncm" label="NCM" type="text"
                                :error-messages="formErrors.ncm"
                                hide-details="auto"/>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field v-model="payload.amount" label="Valor*" prefix="R$" type="number"
                                :error-messages="formErrors.amount"
                                hide-details="auto" :rules="[$rules.required]"/>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field v-model="payload.calories" label="Calorias" type="number"
                                :error-messages="formErrors.calories"
                                hide-details="auto"/>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field v-model="payload.max_order_quantity"
                                label="Quantidade máxima de compra*" type="number"
                                :error-messages="formErrors.max_order_quantity"
                                hide-details="auto" :rules="[$rules.required]"/>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <a-category-search v-model="payload.category_id" label="Categoria*" type="text"
                                     :error-messages="formErrors.category_id"
                                     hide-details="auto"/>
                </v-col>
                <v-col cols="12">
                  <v-textarea auto-grow rows="1" v-model="payload.description" label="Descrição" type="text"
                              :error-messages="formErrors.description"
                              hide-details="auto"/>
                </v-col>
                <v-col cols="12" md="6">
                  <v-switch flat inset v-model="payload.active" label="Visível na Loja"
                            :error-messages="formErrors.active"
                            hide-details="auto"/>
                </v-col>
                <v-col cols="12" md="6">
                  <v-switch flat inset v-model="payload.featured" label="Destaque na Loja"
                            :error-messages="formErrors.featured"
                            hide-details="auto"/>
                </v-col>

                <v-col cols="12" class="pt-5">
                  <h1 class="text-subtitle-1 font-weight-light text-uppercase">Informações de Restrição de Idade</h1>
                </v-col>
                <v-col cols="12">
                  <v-switch flat inset v-model="payload.alcoholic" label="Produto Alcoólico"
                            :error-messages="formErrors.alcoholic"
                            hide-details="auto"/>
                </v-col>

                <v-col cols="12" class="pt-5">
                  <h1 class="text-subtitle-1 font-weight-light text-uppercase">Informações alimentares</h1>
                </v-col>
                <v-col cols="12">
                  <v-switch flat inset v-model="payload.vegan" label="Produto Vegano"
                            :error-messages="formErrors.vegan"
                            hide-details="auto"/>
                </v-col>

                <v-col cols="12" class="pt-5">
                  <h1 class="text-subtitle-1 font-weight-light text-uppercase">Informações de Estoque</h1>
                </v-col>
                <v-col cols="12">
                  <v-switch flat inset v-model="payload.manage_stock" label="Gerenciar Estoque"
                            :error-messages="formErrors.manage_stock"
                            hide-details="auto"/>
                </v-col>

                <v-col cols="12" class="pt-5">
                  <h1 class="text-subtitle-1 font-weight-light text-uppercase">Opções da Cozinha</h1>
                </v-col>
                <v-col cols="12">
                  <v-switch flat inset v-model="payload.item_to_prepare" label="O Item é Preparável"
                            :error-messages="formErrors.item_to_prepare"
                            hide-details="auto"/>
                </v-col>
                <template v-if="payload.item_to_prepare">
                  <v-col cols="12">
                    <v-text-field v-model="payload.preparation_time" label="Tempo de preparo*" suffix="min" type="number"
                                  :error-messages="formErrors.preparation_time"
                                  hide-details="auto" :rules="[$rules.required]"/>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea auto-grow rows="1" v-model="payload.recipe" label="Receita"
                                :error-messages="formErrors.recipe"
                                hide-details="auto"/>
                  </v-col>
                </template>

                <v-col cols="12" class="pt-5">
                  <h1 class="text-subtitle-1 font-weight-light text-uppercase">Imagem do item</h1>
                </v-col>
                <v-col cols="12">
                  <a-thumbnail-search v-model="payload.thumbnail_id"/>
                </v-col>

                <v-col cols="12">
                  <h1 class="text-subtitle-1 font-weight-light text-uppercase">Grupos</h1>
                </v-col>
                <v-col cols="12">
                  <a-group-search v-model="payload.groups"/>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn text @click="$refs.form.reset()">Limpar</v-btn>
            <v-btn depressed color="primary" @click="storeItem()" v-if="creating">Salvar</v-btn>
            <v-btn depressed color="primary" @click="updateItem()" v-if="updating">Alterar</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  import AThumbnailSearch from "@/components/AThumbnailSearch";
  import AGroupSearch from "@/components/AGroupSearch";
  import ACategorySearch from "@/components/ACategorySearch";
  export default {
    name: 'a-item-form',
    components: {ACategorySearch, AGroupSearch, AThumbnailSearch},
    data: () => ({
      formErrors: {},
      payload: {
        name: '',
        unity: '',
        sku: '',
        barcode: '',
        brand: '',
        ncm: '',
        amount: '',
        calories: '',
        preparation_time: '',
        max_order_quantity: '',
        description: '',
        active: false,
        featured: false,
        alcoholic: false,
        vegan: false,
        tensor: '',
        promotion: false,
        promotion_amount: '',
        enable_loyalty: false,
        loyalty_pay: '',
        loyalty_charge: '',
        manage_stock: false,
        item_to_prepare: false,
        recipe: '',
        category_id: '',
        thumbnail_id: '',
        groups: []
      },
      valid: true,
    }),
    created() {
      if (this.updating) {
        this.$store.dispatch('fetchItem', {
          business: this.business.id,
          item: this.$route.params.item
        }).then((response) => {
          this.payload = {
            name: response.data.data.name,
            unity: response.data.data.unity,
            sku: response.data.data.sku,
            barcode: response.data.data.barcode,
            brand: response.data.data.brand,
            ncm: response.data.data.ncm,
            amount: response.data.data.amount,
            calories: response.data.data.calories,
            preparation_time: response.data.data.preparation_time,
            max_order_quantity: response.data.data.max_order_quantity,
            description: response.data.data.description,
            alcoholic: response.data.data.alcoholic,
            vegan: response.data.data.vegan,
            active: response.data.data.active,
            featured: response.data.data.featured,
            tensor: response.data.data.tensor,
            promotion: response.data.data.promotion,
            promotion_amount: response.data.data.promotion_amount,
            enable_loyalty: response.data.data.enable_loyalty,
            loyalty_pay: response.data.data.loyalty_pay,
            loyalty_charge: response.data.data.loyalty_charge,
            manage_stock: response.data.data.manage_stock,
            item_to_prepare: response.data.data.item_to_prepare,
            recipe: response.data.data.recipe,
            category_id: response.data.data.category ? response.data.data.category.id : '',
            thumbnail_id: response.data.data.thumbnail ? response.data.data.thumbnail.id : '',
            groups: response.data.data.groups ? response.data.data.groups.map(group => group.id) : [],
          }
        });
      }
    },
    methods: {
      storeItem() {
        if (!this.$refs.form.validate()) {
          alert('Verifique os campos do formulário!');
          return;
        }
        this.$store.dispatch('createItem', {
          business: this.business.id,
          payload: this.payload
        }).then(() => {
          this.$refs.form.reset();
          this.$router.back();
        }).catch((error) => {
          if (error.response && error.response.status === 422) {
            this.formErrors = error.response.data.errors;
          }
        });
      },
      updateItem() {
        if (!this.$refs.form.validate()) {
          alert('Verifique os campos do formulário!');
          return;
        }
        this.$store.dispatch('updateItem', {
          business: this.business.id,
          item: this.$route.params.item,
          payload: this.payload
        }).then(() => {
          this.$refs.form.reset();
          this.$router.back();
        }).catch((error) => {
          if (error.response && error.response.status === 422) {
            this.formErrors = error.response.data.errors;
          }
        });
      },
    },
    computed: {
      creating() {
        return this.$route.name === 'items_create';
      },
      updating() {
       return this.$route.name === 'items_update';
      },
      business() {
        return this.$store.getters.getLoginAuthBusiness;
      },
    },
  };
</script>
