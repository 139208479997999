<template>
  <v-card flat outlined v-bind="$attrs" v-if="order">
    <v-list-item two-line>
      <v-list-item-avatar rounded size="80">
        <v-img :src="order.user.avatar ? order.user.avatar.url : require('../assets/placeholder.png')" aspect-ratio="1"/>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>#{{ order.identification }} - <span :class="orderStatusColor(order.status)">{{ orderStatus(order.status) }}</span></v-list-item-title>
        <v-list-item-subtitle>{{ order.user.name }}, comprou {{ order.user_order_requests }}x</v-list-item-subtitle>
        <v-list-item-subtitle>Previsão {{ DateTime.fromSQL(order.delivery_prevision).setLocale('pt-BR').toFormat('dd LLL yyyy t') }}</v-list-item-subtitle>
        <v-list-item-subtitle>Valor: {{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(order.total) }} - {{ serviceName(order.service) }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-card-actions>
      <v-btn text @click="showOrder">
        Comanda
      </v-btn>
      <template v-if="order.status === 'PENDING'">
        <v-btn text @click="prepareOrder">
          Preparar
        </v-btn>
      </template>
      <template v-if="order.status !== 'PENDING'">
        <v-btn text :to="{ name: 'payments', params: { order: order.id } }">
          Pagamentos
        </v-btn>
      </template>
      <template v-if="order.status !== 'PENDING' && order.status !== 'DELIVERED' && order.status !== 'CANCELLED'">
        <v-btn text :to="{ name: 'message', params: { order: order.id } }">
          Chat
        </v-btn>
      </template>
      <template v-if="order.status === 'PENDING'">
        <v-spacer/>
        <v-btn text @click="cancelOrder">
          Rejeitar
        </v-btn>
      </template>
    </v-card-actions>
  </v-card>
</template>

<script>
import { DateTime } from "luxon";

export default {
  inheritAttrs: false,
  name: 'a-order-tile',
  props: {
    order: Object,
  },
  data: () => ({
    DateTime: DateTime,
  }),
  methods: {
    showOrder() {
      let url = `${window.location.origin}/print/${this.order.id}`;
      let target = '_blank';
      let width = this.printerColumns * 11;
      let height = 550;
      let features = `width=${width},height=${height},fullscreen=no,menubar=no,resizable=no,scrollbars=yes,status=no,titlebar=no,toolbar=no`;
      window.open(url, target, features);
    },
    prepareOrder() {
      if (confirm(`Tem certeza que deseja preparar o pedido #${this.order.identification}?`)) {
        this.$store.dispatch('updateOrder', {
          business: this.business.id,
          order: this.order.id,
          payload: { status: 'PREPARING' }
        }).then((response) => {
          this.order = response.data.data;
        });
      }
    },
    cancelOrder() {
      if (confirm(`Tem certeza que deseja cancelar o pedido #${this.order.identification}?`)) {
        this.$store.dispatch('updateOrder', {
          business: this.business.id,
          order: this.order.id,
          payload: { status: 'CANCELLED' }
        }).then((response) => {
          this.order = response.data.data;
        });
      }
    },
    orderStatusColor(status) {
      switch (status) {
        case 'PENDING':
          return 'amber--text darken-4';
        case 'PREPARING':
          return 'blue--text darken-4';
        case 'DELIVERING':
          return 'purple--text darken-4';
        case 'DELIVERED':
          return 'green--text darken-4';
        case 'CANCELLED':
          return 'red--text darken-4';
        default:
          return 'grey--text darken-4';
      }
    },
    orderStatus(status) {
      switch (status) {
        case 'PENDING':
          return 'Pendente';
        case 'PREPARING':
          return 'Preparando';
        case 'DELIVERING':
          return 'Saiu Para Entrega';
        case 'DELIVERED':
          return 'Entregue';
        case 'CANCELLED':
          return 'Cancelado';
        default:
          return 'Não definido';
      }
    },
    paymentStatus(status) {
      switch (status) {
        case 'PENDING':
          return 'Processando';
        case 'SUCCEEDED':
          return 'Aprovado';
        case 'FAILED':
          return 'Rejeitado';
        case 'CANCELLED':
          return 'Cancelado';
        case 'CASH_BACK':
          return 'Estornado';
        default:
          return 'Não definido';
      }
    },
    serviceName(service) {
      switch (service) {
        case 'DELIVERY':
          return 'Delivery';
        case 'PICK_UP':
          return 'Retirada';
        case 'DINE_IN':
          return 'Em Mesa';
        default:
          return 'Listagem dos dados';
      }
    },
  },
  computed: {
    business() {
      return this.$store.getters.getLoginAuthBusiness;
    },
    printerColumns() {
      return this.$store.getters.getPrinterColumns;
    }
  },
}
</script>
