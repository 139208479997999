import resource from '../../resource';

const state = {
  items: null,
};

const getters = {
  getItems(state) {
    return state.items;
  },
};

const mutations = {
  SET_ITEMS(state, data) {
    state.items = data;
  },
};

const actions = {
  fetchItems(context, { business }) {
    return resource.get(`/businesses/${business}/items`)
      .then((response) => {
        context.commit('SET_ITEMS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  fetchItem(context, { business, item }) {
    return resource.get(`/businesses/${business}/items/${item}`)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  searchItems(context, { business, search }) {
    return resource.get(`/businesses/${business}/items`, { params: { name: search } })
      .then((response) => {
        context.commit('SET_ITEMS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  toItemsPage(context, { business, search, page }) {
    return resource.get(`/businesses/${business}/items`, { params: { name: search, page: page } })
      .then((response) => {
        context.commit('SET_ITEMS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  createItem(context, { business, payload }) {
    return resource.post(`/businesses/${business}/items`, payload)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  updateItem(context, { business, item, payload }) {
    return resource.put(`/businesses/${business}/items/${item}`, payload)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  deleteItem(context, { business, item }) {
    return resource.delete(`/businesses/${business}/items/${item}`)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
