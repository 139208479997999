<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat dark color="primary">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title v-if="creating">Criar Cupom</v-list-item-title>
            <v-list-item-title v-if="updating">Alterar Cupom</v-list-item-title>
            <v-list-item-subtitle>Listagem dos dados</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn @click="$router.back()" icon>
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-col>
    <template v-if="payload">
      <v-col cols="12">
        <v-card flat outlined>
          <v-card-text>
            <v-form ref="form" v-model="valid">
              <v-row dense>
                <v-col cols="6">
                  <v-select v-model="payload.type" :items="selectTypeOptions" label="Tipo*"
                            :error-messages="formErrors.type"
                            hide-details="auto" :rules="[$rules.required]"/>
                </v-col>
                <v-col cols="6" v-if="payload.type === 'DECIMAL'">
                  <v-text-field v-model="payload.amount" label="Valor*" type="number"
                                :error-messages="formErrors.amount"
                                hide-details="auto" :rules="[$rules.required]"/>
                </v-col>
                <v-col cols="6" v-if="payload.type === 'PERCENTAGE'">
                  <v-text-field v-model="payload.percentage" label="Porcentagem*" type="number"
                                :error-messages="formErrors.percentage"
                                hide-details="auto" :rules="[$rules.required]"/>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="payload.quantity" label="Quantidade*" type="number"
                                :error-messages="formErrors.quantity"
                                hide-details="auto" :rules="[$rules.required]"/>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="payload.valid_until" label="Validade*" type="datetime-local" step="1"
                                :error-messages="formErrors.valid_until"
                                hide-details="auto" :rules="[$rules.required]"/>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="payload.min_amount" label="Mínimo em compra" type="number"
                                :error-messages="formErrors.min_amount"
                                hide-details="auto"/>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="payload.max_amount" label="Máximo em compra" type="number"
                                :error-messages="formErrors.max_amount"
                                hide-details="auto"/>
                </v-col>
                <v-col cols="12">
                  <v-switch flat inset v-model="payload.active" label="Ativo"
                            :error-messages="formErrors.active"
                            hide-details="auto"/>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn text @click="$refs.form.reset()">Limpar</v-btn>
            <v-btn depressed color="primary" @click="storeCoupon()" v-if="creating">Salvar</v-btn>
            <v-btn depressed color="primary" @click="updateCoupon()" v-if="updating">Alterar</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  export default {
    name: 'a-coupon-form',
    data: () => ({
      formErrors: {},
      payload: {
        type: 'DECIMAL',
        amount: '',
        percentage: '',
        quantity: '',
        valid_until: '',
        min_amount: '',
        max_amount: '',
        active: '',
      },
      selectTypeOptions: [
        { text: 'Valor', value: 'DECIMAL' },
        { text: 'Porcentagem', value: 'PERCENTAGE' },
        { text: 'Frete Gratis', value: 'DELIVERY_FREE' },
      ],
      valid: true,
    }),
    created() {
      if (this.updating) {
        this.$store.dispatch('fetchCoupon', {
          business: this.business.id,
          coupon: this.$route.params.coupon
        }).then((response) => {
          this.payload = {
            type: response.data.data.type,
            amount: response.data.data.amount,
            percentage: response.data.data.percentage,
            quantity: response.data.data.quantity,
            valid_until: response.data.data.valid_until,
            min_amount: response.data.data.min_amount,
            max_amount: response.data.data.max_amount,
            active: response.data.data.active,
          }
        });
      }
    },
    methods: {
      storeCoupon() {
        if (!this.$refs.form.validate()) {
          alert('Verifique os campos do formulário!');
          return;
        }
        this.$store.dispatch('createCoupon', {
          business: this.business.id,
          payload: this.payload
        }).then(() => {
          this.$refs.form.reset();
          this.$router.back();
        }).catch((error) => {
          if (error.response && error.response.status === 422) {
            this.formErrors = error.response.data.errors;
          }
        });
      },
      updateCoupon() {
        if (!this.$refs.form.validate()) {
          alert('Verifique os campos do formulário!');
          return;
        }
        this.$store.dispatch('updateCoupon', {
          business: this.business.id,
          coupon: this.$route.params.coupon,
          payload: this.payload
        }).then(() => {
          this.$refs.form.reset();
          this.$router.back();
        }).catch((error) => {
          if (error.response && error.response.status === 422) {
            this.formErrors = error.response.data.errors;
          }
        });
      },
    },
    computed: {
      creating() {
        return this.$route.name === 'coupons_create';
      },
      updating() {
       return this.$route.name === 'coupons_update';
      },
      business() {
        return this.$store.getters.getLoginAuthBusiness;
      },
    },
  };
</script>
