import resource from '../../resource';

const state = {
  options: null,
};

const getters = {
  getOptions(state) {
    return state.options;
  },
};

const mutations = {
  SET_OPTIONS(state, data) {
    state.options = data;
  },
};

const actions = {
  fetchOptions(context, { business, item }) {
    return resource.get(`/businesses/${business}/items/${item}/options`)
      .then((response) => {
        context.commit('SET_OPTIONS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  fetchOption(context, { business, item, option }) {
    return resource.get(`/businesses/${business}/items/${item}/options/${option}`)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  searchOptions(context, { business, item, search }) {
    return resource.get(`/businesses/${business}/items/${item}/options`, { params: { name: search } })
      .then((response) => {
        context.commit('SET_OPTIONS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  toOptionsPage(context, { business, item, search, page }) {
    return resource.get(`/businesses/${business}/items/${item}/options`, { params: { name: search, page: page } })
      .then((response) => {
        context.commit('SET_OPTIONS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  createOption(context, { business, item, payload }) {
    return resource.post(`/businesses/${business}/items/${item}/options`, payload)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  updateOption(context, { business, item, option, payload }) {
    return resource.put(`/businesses/${business}/items/${item}/options/${option}`, payload)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  deleteOption(context, { business, item, option }) {
    return resource.delete(`/businesses/${business}/items/${item}/options/${option}`)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
