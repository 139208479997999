import resource from '../../resource';

const state = {
  drivers: null,
};

const getters = {
  getDrivers(state) {
    return state.drivers;
  },
};

const mutations = {
  SET_DRIVERS(state, data) {
    state.drivers = data;
  },
};

const actions = {
  fetchDrivers(context, { business }) {
    return resource.get(`/businesses/${business}/drivers`)
      .then((response) => {
        context.commit('SET_DRIVERS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  fetchDriver(context, { business, driver }) {
    return resource.get(`/businesses/${business}/drivers/${driver}`)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  searchDrivers(context, { business, search }) {
    return resource.get(`/businesses/${business}/drivers`, { params: { name: search } })
      .then((response) => {
        context.commit('SET_DRIVERS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  toDriversPage(context, { business, search, page }) {
    return resource.get(`/businesses/${business}/drivers`, { params: { name: search, page: page } })
      .then((response) => {
        context.commit('SET_DRIVERS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  createDriver(context, { business, payload }) {
    return resource.post(`/businesses/${business}/drivers`, payload)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  updateDriver(context, { business, driver, payload }) {
    return resource.put(`/businesses/${business}/drivers/${driver}`, payload)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  deleteDriver(context, { business, driver }) {
    return resource.delete(`/businesses/${business}/drivers/${driver}`)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
