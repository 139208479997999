<template>
  <v-autocomplete v-bind="$attrs" v-model="paymentMethodSelected" :items="paymentMethods" item-text="name" item-value="id"/>
</template>

<script>
  import resource from "../resource";

  export default {
    inheritAttrs: false,
    name: 'a-payment-method-search',
    props: ['value', 'type'],
    data: () => ({
      paymentMethodsSearch: '',
      paymentMethodSelected: null,
      paymentMethods: [],
      loading: false,
    }),
    created() {
      this.loading = true;
      resource.get(`businesses/${this.business.id}/payment_methods`, { params: { all: 1 } }).then((response) => {
        this.loading = false;
        this.paymentMethods = response.data.data;
      });
      if (this.value) {
        this.paymentMethodSelected = this.value;
      }
    },
    watch: {
      value(value) {
        this.paymentMethodSelected = value;
      },
      type(value) {
        this.loading = true;
        resource.get(`businesses/${this.business.id}/payment_methods`, { params: { all: 1, type: value } })
          .then((response) => {
            this.loading = false;
            this.paymentMethods = response.data.data;
          });
      },
      paymentMethodSelected(value) {
        this.$emit('input', value);
      },
    },
    computed: {
      business() {
        return this.$store.getters.getLoginAuthBusiness;
      },
    },
  }
</script>
