import APeriodList from './index'
import APeriodForm from './form'

export default [
  {
    path: '/periods/',
    component: APeriodList,
    name: 'periods',
    meta: {
     requiresAuth: true
    }
  },
  {
    path: '/periods/create',
    component: APeriodForm,
    name: 'periods_create',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/periods/:period/update',
    component: APeriodForm,
    name: 'periods_update',
    meta: {
      requiresAuth: true
    }
  },
];
