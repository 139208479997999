<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat dark color="primary">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title v-if="creating">Criar Avaliação</v-list-item-title>
            <v-list-item-title v-if="updating">Alterar Avaliação</v-list-item-title>
            <v-list-item-subtitle>Listagem dos dados</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn @click="$router.back()" icon>
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-col>
    <template v-if="payload">
      <v-col cols="12">
        <v-card flat outlined>
          <v-card-text>
            <v-form ref="form" v-model="valid">
              <v-row dense>
                <v-col cols="12">
                  <v-textarea disabled auto-grow rows="1" v-model="payload.comment" label="Comentário"
                              :error-messages="formErrors.comment"
                              hide-details="auto"/>
                </v-col>
                <v-col cols="12">
                  <v-textarea auto-grow rows="1" v-model="payload.replica" label="Resposta*" :rules="[$rules.required]"
                              :error-messages="formErrors.replica"
                              hide-details="auto"/>
                </v-col>

                <v-col cols="12">
                  <v-switch inset v-model="payload.moderation" label="Pedir Moderação" hide-details="auto"/>
                </v-col>
                <v-col cols="12" v-if="payload.moderation">
                  <v-select v-model="payload.moderation_reason" :items="selectModerationReasons" label="Motivo da moderação*"
                            :error-messages="formErrors.moderation_reason"
                            hide-details="auto" :rules="[$rules.required]"/>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn text @click="$refs.form.reset()">Limpar</v-btn>
            <v-btn depressed color="primary" @click="storeRating()" v-if="creating">Salvar</v-btn>
            <v-btn depressed color="primary" @click="updateRating()" v-if="updating">Alterar</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  export default {
    name: 'a-rating-form',
    data: () => ({
      formErrors: {},
      payload: {
        comment: '',
        replica: '',
        moderation: '',
        moderation_reason: '',
      },
      selectModerationReasons: [
        { text: 'Linguágem Inapropriada', value: 'INAPPROPRIATE_LANGUAGE' },
        { text: 'Conteúdo Falso ou Spam', value: 'FAKE_CONTENT' },
        { text: 'Avaliação Equivocada', value: 'WRONG_ASSESSMENT' },
        { text: 'Reclamação Sobre o Aplicativo', value: 'APP_COMPLAINT' },
        { text: 'Concordância Com o Cliente', value: 'AGREEMENT_TO_CLIENT' },
      ],
      valid: true,
    }),
    created() {
      if (this.updating) {
        this.$store.dispatch('fetchRating', {
          business: this.business.id,
          rating: this.$route.params.rating
        }).then((response) => {
          this.payload = {
            comment: response.data.data.comment,
            replica: response.data.data.replica,
            moderation: response.data.data.moderation,
            moderation_reason: response.data.data.moderation_reason,
          }
        });
      }
    },
    methods: {
      storeRating() {
        if (!this.$refs.form.validate()) {
          alert('Verifique os campos do formulário!');
          return;
        }
        this.$store.dispatch('createRating', {
          business: this.business.id,
          payload: this.payload
        }).then(() => {
          this.$refs.form.reset();
          this.$router.back();
        }).catch((error) => {
          if (error.response && error.response.status === 422) {
            this.formErrors = error.response.data.errors;
          }
        });
      },
      updateRating() {
        if (!this.$refs.form.validate()) {
          alert('Verifique os campos do formulário!');
          return;
        }
        this.$store.dispatch('updateRating', {
          business: this.business.id,
          rating: this.$route.params.rating,
          payload: this.payload
        }).then(() => {
          this.$refs.form.reset();
          this.$router.back();
        }).catch((error) => {
          if (error.response && error.response.status === 422) {
            this.formErrors = error.response.data.errors;
          }
        });
      },
    },
    computed: {
      creating() {
        return this.$route.name === 'ratings_create';
      },
      updating() {
       return this.$route.name === 'ratings_update';
      },
      business() {
        return this.$store.getters.getLoginAuthBusiness;
      },
    },
  };
</script>
