<template>
  <v-card flat outlined class="fill-height">
    <v-card-text>
      <v-btn depressed block @click="showTotems" class="mb-5">
        <v-icon left>mdi-qrcode</v-icon> Imprimir códigos Qr de mesa
      </v-btn>
      <span class="d-block text-center">
        Imprima aqui os códigos Qr para as mesas, o código Qr facilita o cacesso dos clientes à plataforma.
      </span>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    name: 'a-profile-qrcode-print',
    methods: {
      showTotems() {
        let url = `${window.location.origin}/qrcode`;
        let target = '_blank';
        let width = 700;
        let height = 1500;
        let features = `width=${width},height=${height},fullscreen=no,menubar=no,resizable=no,scrollbars=yes,status=no,titlebar=no,toolbar=no`;
        window.open(url, target, features);
      },
    },
  };
</script>
