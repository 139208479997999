<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <a-profile-avatar-update/>
    </v-col>
    <v-col cols="12">
      <a-profile-qrcode-print/>
    </v-col>

    <v-col cols="12" class="pt-5">
      <h1 class="text-subtitle-1 font-weight-light text-uppercase">Termos e Condições</h1>
    </v-col>
    <v-col cols="12">
      <span class="pt-3">Leia os
        <router-link :to="{ name: 'terms' }" target="_blank">Termos e Condições</router-link> e as
        <router-link :to="{ name: 'policies' }" target="_blank">Politicas de Privacidade</router-link>
      </span>
    </v-col>
  </v-row>
</template>

<script>
import AProfileQrcodePrint from "@/components/Profile/AProfileQrcodePrint";
import AProfileAvatarUpdate from "@/components/Profile/AProfileAvatarUpdate";

export default {
  name: 'a-register-business',
  components: {AProfileAvatarUpdate, AProfileQrcodePrint},
};
</script>
