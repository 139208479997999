import ADashboard from './index'

export default [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: ADashboard,
    meta: {
      requiresAuth: true,
    },
  },
];
