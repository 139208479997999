import resource from '../../resource';

const state = {
  waiters: null,
};

const getters = {
  getWaiters(state) {
    return state.waiters;
  },
};

const mutations = {
  SET_WAITERS(state, data) {
    state.waiters = data;
  },
};

const actions = {
  fetchWaiters(context, { business }) {
    return resource.get(`/businesses/${business}/waiters`)
      .then((response) => {
        context.commit('SET_WAITERS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  fetchWaiter(context, { business, waiter }) {
    return resource.get(`/businesses/${business}/waiters/${waiter}`)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  searchWaiters(context, { business, search }) {
    return resource.get(`/businesses/${business}/waiters`, { params: { name: search } })
      .then((response) => {
        context.commit('SET_WAITERS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  toWaitersPage(context, { business, search, page }) {
    return resource.get(`/businesses/${business}/waiters`, { params: { name: search, page: page } })
      .then((response) => {
        context.commit('SET_WAITERS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  createWaiter(context, { business, payload }) {
    return resource.post(`/businesses/${business}/waiters`, payload)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  updateWaiter(context, { business, waiter, payload }) {
    return resource.put(`/businesses/${business}/waiters/${waiter}`, payload)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  deleteWaiter(context, { business, waiter }) {
    return resource.delete(`/businesses/${business}/waiters/${waiter}`)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
