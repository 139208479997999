import resource from "@/resource";

const state = {
  profile: null,
  categories: null,
  addresses: null,
  telephones: null,
  thumbnails: null,
  paymentMethods: null,
};

const getters = {
  getProfile(state) {
    return state.profile;
  },
  getProfileCategories(state) {
    return state.categories;
  },
  getProfileAddresses(state) {
    return state.addresses;
  },
  getProfileTelephones(state) {
    return state.telephones;
  },
  getProfileThumbnails(state) {
    return state.thumbnails;
  },
  getProfilePaymentMethods(state) {
    return state.paymentMethods;
  },
};

const mutations = {
  SET_PROFILE(state, data) {
    state.profile = data;
  },
  SET_PROFILE_CATEGORIES(state, data) {
    state.categories = data;
  },
  SET_PROFILE_ADDRESSES(state, data) {
    state.addresses = data;
  },
  SET_PROFILE_TELEPHONES(state, data) {
    state.telephones = data;
  },
  SET_PROFILE_THUMBNAILS(state, data) {
    state.thumbnails = data;
  },
  SET_PROFILE_PAYMENT_METHODS(state, data) {
    state.paymentMethods = data;
  },
};

const actions = {
  fetchProfile(context, { business }) {
    return resource.get(`/businesses/${business}`)
      .then((response) => {
        context.commit('SET_PROFILE', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  fetchProfileCategories(context) {
    return resource.get(`/categories`, { params: { all: 1 } })
      .then((response) => {
        context.commit('SET_PROFILE_CATEGORIES', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  fetchProfileAddresses(context, { business }) {
    return resource.get(`/businesses/${business}/addresses`, { params: { all: 1 } })
      .then((response) => {
        context.commit('SET_PROFILE_ADDRESSES', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  fetchProfileTelephones(context, { business }) {
    return resource.get(`/businesses/${business}/telephones`, { params: { all: 1 } })
      .then((response) => {
        context.commit('SET_PROFILE_TELEPHONES', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  fetchProfileThumbnails(context, { business }) {
    return resource.get(`/businesses/${business}/thumbnails`, { params: { all: 1 } })
      .then((response) => {
        context.commit('SET_PROFILE_THUMBNAILS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  fetchProfilePaymentMethods(context) {
    return resource.get('/payment_methods', { params: { all: 1 } })
      .then((response) => {
        context.commit('SET_PROFILE_PAYMENT_METHODS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  updateProfile(context, { business, payload }) {
    return resource.put(`/businesses/${business}`, payload)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
