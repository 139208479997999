import APaymentList from './index'
import APaymentForm from './form'

export default [
  {
    path: '/orders/:order/payments/',
    component: APaymentList,
    name: 'payments',
    meta: {
     requiresAuth: true
    }
  },
  {
    path: '/orders/:order/payments/create',
    component: APaymentForm,
    name: 'payments_create',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/orders/:order/payments/:payment/update',
    component: APaymentForm,
    name: 'payments_update',
    meta: {
      requiresAuth: true
    }
  },
];
