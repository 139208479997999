const state = {
  //
};

const getters = {
  //
};

const mutations = {
  //
};

const actions = {
  //
};

export default {
  state,
  getters,
  actions,
  mutations
};
