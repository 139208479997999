<template>
  <v-row dense align-content="start" justify="center" class="fill-height">
    <v-col cols="12" class="d-print-none">
      <v-btn block depressed @click="printPage">
        <v-icon left>mdi-printer</v-icon>Imprimir
      </v-btn>
    </v-col>
    <pre>{{ receipt }}</pre>
  </v-row>
</template>

<script>
import { DateTime } from "luxon";
const EOL = '\n';

export default {
  name: 'a-print',
  data: () => ({
    order: null,
    receipt: '',
  }),
  watch: {
    columns() {
      this.prepareReceipt();
    },
  },
  created() {
    this.$store.dispatch('fetchPrintOrder', {
      business: this.business.id,
      order: this.$route.params.order
    }).then((response) => {
      this.order = response.data.data;
      this.prepareReceipt();
    });
  },
  methods: {
    printPage() {
      window.print();
    },
    prepareReceipt() {
      let receiptPayload = [];

      receiptPayload.push(this.empty());
      receiptPayload.push(this.ruler());
      receiptPayload.push(this.text('PointUrbano', null, 'center'));
      receiptPayload.push(this.empty());

      receiptPayload.push(this.text(`Pedido: #${this.order.identification}`));
      receiptPayload.push(this.text(`Loja: ${this.order.business.name}`));
      receiptPayload.push(this.text(`Data: ${DateTime.fromSQL(this.order.created_at).setLocale('pt-BR').toFormat('dd LLL yyyy t')}`));
      receiptPayload.push(this.text(`Previsão: ${DateTime.fromSQL(this.order.delivery_prevision).setLocale('pt-BR').toFormat('dd LLL yyyy t')}`));

      if (this.order.user_order_requests > 1) {
        receiptPayload.push(this.empty());
        receiptPayload.push(this.text(`${this.order.user_order_requests} pedidos nessa loja`));
      }

      if (this.order.fiscal_note) {
        receiptPayload.push(this.empty());
        receiptPayload.push(this.text(`CPF na nota: ${this.cpfFormat(this.order.cpf)}`));
      }

      receiptPayload.push(this.empty());
      receiptPayload.push(this.text('Dados do cliente'));

      if (this.order.user) {
        receiptPayload.push(this.text(`Nome: ${this.order.user.name}`));
        if (this.order.user.telephone) {
          receiptPayload.push(this.text(`Telefone: ${this.order.user.telephone.telephone}`));
        }
      }

      if (this.order.service === 'DINE_IN') {
        receiptPayload.push(this.text(`Mesa: ${this.order.totem.number}`));
      }

      if (this.order.service === 'PICK_UP') {
        receiptPayload.push(this.text(`Apelido: ${this.order.pick_up_nickname}`));
      }

      if (this.order.service === 'DELIVERY') {
        receiptPayload.push(this.text(`Endereço: ${this.order.address.address}`));
        receiptPayload.push(this.text(`Bairro: ${this.order.address.district}`));
        receiptPayload.push(this.text(`Comp: ${this.order.address.complement}`));
        receiptPayload.push(this.text(`Ref: ${this.order.address.reference}`));
        receiptPayload.push(this.text(`Cidade: ${this.order.address.city.name} - ${this.order.address.city.state.uf}`));
        receiptPayload.push(this.text(`CEP: ${this.order.address.cep}`));
      }

      receiptPayload.push(this.empty());
      receiptPayload.push(this.text('Itens do pedido'));

      receiptPayload.push(this.items(this.order.order_items));
      receiptPayload.push(this.text(`Valor total dos itens ${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(this.order.amount)}`, null, 'center'));

      if (this.order.service === 'DELIVERY') {
        receiptPayload.push(this.empty());
        receiptPayload.push(this.text('Taxa de entrega'));
        receiptPayload.push(this.text(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(this.order.delivery_tax)));
      }

      if (this.order.coupon) {
        receiptPayload.push(this.empty());
        receiptPayload.push(this.text('Cupom de desconto'));

        if (this.order.coupon.type === 'DECIMAL') {
          receiptPayload.push(this.text(`${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(this.order.coupon.amount)} sobre os itens`));
        }

        if (this.order.coupon.type === 'PERCENTAGE') {
          receiptPayload.push(this.text(`${this.order.coupon.percentage}% sobre os itens`));
        }

        if (this.order.coupon.type === 'DELIVERY_FREE') {
          receiptPayload.push(this.text('Frete grátis'));
        }
      }

      receiptPayload.push(this.empty());
      receiptPayload.push(this.text('Valor total do pedido'));
      receiptPayload.push(this.text(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(this.order.total)));

      receiptPayload.push(this.empty());
      receiptPayload.push(this.text('Pagamento'));

      this.order.payments.forEach((payment) => {
        receiptPayload.push(this.text(`${this.paymentType(payment.type)} - ${payment.payment_method.name}`));

        if (payment.type === 'CURRENCY' &&  payment.cash_change) {
          receiptPayload.push(this.text(`Requer troco para ${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(payment.cash_change)}`));
        }

        if (payment.type === 'IN_APP') {
          receiptPayload.push(this.empty());
          receiptPayload.push(this.ruler());
          receiptPayload.push(this.text('Não há cobrança na entrega do pedido!', null, 'center'));
          receiptPayload.push(this.ruler());
        }
      })

      receiptPayload.push(this.empty());

      this.receipt = receiptPayload.map((line) => {
        return line.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
      }).join(EOL);
    },
    pad(value, char, length, side = 'right') {
      let required = Math.floor(length) - value.toString().length;

      while (required > 0) {
        required -= 1;
        if (side === 'left') value = char + value;
        if (side === 'right') value = value + char;
        if (side === 'center') {
          if (required % 2 === 0) value = char + value;
          else value = value + char;
        }
      }

      return value;
    },
    empty() {
      return this.pad('', ' ', this.columns, 'right');
    },
    ruler() {
      return this.pad('', '-', this.columns, 'right');
    },
    text(value, padding = null, align = 'right') {
      let chars = this.columns - (padding ? (align === 'center' ? padding * 2 : padding) : 0);
      let words = value.split(/\s/g);
      let lines = [];
      let line = '';

      words.reverse();

      while (words.length > 0) {
        let word = words.pop();
        if (line.length + word.length > chars) {
          lines.push(line);
          line = '';
        }
        line += word + ' ';
      }

      lines.push(line);

      if (lines) {
        return lines.map((line) => {
          line = line.replace(/\s+$|^\s+/, '');
          return this.pad(line, ' ', this.columns, align);
        }).join(EOL);
      }

      return '';
    },
    items(orderItems) {
      let lines = [this.ruler()];

      lines.push([
        this.pad('Qtd', ' ', 4, 'right'),
        this.pad('Item', ' ', this.columns - 20, 'right'),
        this.pad('Preco', ' ', 12, 'left')
      ].join(''));

      lines.push(this.ruler());

      for (let orderItem of orderItems) {
        lines.push([
          this.pad(orderItem.quantity, ' ', 4, 'right'),
          this.pad(orderItem.name.substr(0, this.columns - 20), ' ', this.columns - 18, 'right'),
          this.pad(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(orderItem.total), ' ', 12, 'left')
        ].join(''));

        for (let orderOption of orderItem.order_options) {
          lines.push([
            this.pad('', ' ', 4, 'right'),
            this.pad(orderOption.name.substr(0, this.columns - 20), ' ', this.columns - 18, 'right'),
            this.pad('', ' ', 12, 'left')
          ].join(''));

          for (let orderSelection of orderOption.order_selections) {
            lines.push([
              this.pad('', ' ', 6, 'right'),
              this.pad(orderSelection.quantity, ' ', 4, 'right'),
              this.pad(orderSelection.name.substr(0, this.columns - 24), ' ', this.columns - 24, 'right'),
              this.pad(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(orderSelection.amount), ' ', 12, 'left')
            ].join(''));
          }
        }

        if (orderItem.comments) {
          lines.push(this.text(`Obs. ${orderItem.comments}`));
        }
      }

      lines.push(this.ruler());

      return lines.join(EOL);
    },
    orderStatus(status) {
      switch (status) {
        case 'PENDING':
          return 'Pendente';
        case 'PREPARING':
          return 'Preparando';
        case 'DELIVERING':
          return 'Saiu Para Entrega';
        case 'DELIVERED':
          return 'Entregue';
        case 'CANCELLED':
          return 'Cancelado';
        default:
          return 'Não definido';
      }
    },
    paymentStatus(status) {
      switch (status) {
        case 'PENDING':
          return 'Processando';
        case 'SUCCEEDED':
          return 'Aprovado';
        case 'FAILED':
          return 'Rejeitado';
        case 'CANCELLED':
          return 'Cancelado';
        case 'CASH_BACK':
          return 'Estornado';
        default:
          return 'Não definido';
      }
    },
    paymentType(type) {
      switch (type) {
        case 'IN_APP':
          return 'No Aplicativo';
        case 'CREDIT_CARD':
          return 'Cartão de Crédito';
        case 'CURRENCY':
          return 'Dinheiro';
        case 'DEBIT_CARD':
          return 'Cartão de Debito';
        case 'MEAL_TICKET':
          return 'Vale Refeição';
        default:
          return 'Não definido';
      }
    },
    cpfFormat(date) {
      return `${date.slice(0, 3)}.${date.slice(3, 6)}.${date.slice(6, 9)}-${date.slice(9, 11)}`;
    },
  },
  computed: {
    business() {
      return this.$store.getters.getLoginAuthBusiness;
    },
    columns() {
      return this.$store.getters.getPrinterColumns;
    }
  }
};
</script>
