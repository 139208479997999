<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat dark color="primary">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title v-if="creating">Criar Período</v-list-item-title>
            <v-list-item-title v-if="updating">Alterar Período</v-list-item-title>
            <v-list-item-subtitle>Listagem dos dados</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn @click="$router.back()" icon>
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-col>
    <template v-if="payload">
      <v-col cols="12">
        <v-card flat outlined>
          <v-card-text>
            <v-form ref="form" v-model="valid">
              <v-row dense>
                <v-col cols="12" md="4">
                  <v-select v-model="payload.day" :items="selectDayOptions" label="Dia da Semana*"
                            :error-messages="formErrors.day"
                            hide-details="auto" :rules="[$rules.required]"/>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field v-model="payload.time_start" label="Hora Início*" type="time" step="1"
                                :error-messages="formErrors.time_start"
                                hide-details="auto" :rules="[$rules.required]"/>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field v-model="payload.time_end" label="Hora Fim*" type="time" step="1"
                                :error-messages="formErrors.time_end"
                                hide-details="auto" :rules="[$rules.required]"/>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn text @click="$refs.form.reset()">Limpar</v-btn>
            <v-btn depressed color="primary" @click="storePeriod()" v-if="creating">Salvar</v-btn>
            <v-btn depressed color="primary" @click="updatePeriod()" v-if="updating">Alterar</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  export default {
    name: 'a-period-form',
    data: () => ({
      formErrors: {},
      payload: {
        day: '',
        time_start: '',
        time_end: '',
      },
      selectDayOptions: [
        { text: 'Domingo', value: 1 },
        { text: 'Segunda', value: 2 },
        { text: 'Terça', value: 3 },
        { text: 'Quarta', value: 4 },
        { text: 'Quinta', value: 5 },
        { text: 'Sexta', value: 6 },
        { text: 'Sábado', value: 7 },
      ],
      valid: true,
    }),
    created() {
      if (this.updating) {
        this.$store.dispatch('fetchPeriod', {
          business: this.business.id,
          period: this.$route.params.period
        }).then((response) => {
          this.payload = {
            day: response.data.data.day,
            time_start: response.data.data.time_start,
            time_end: response.data.data.time_end,
          }
        });
      }
    },
    methods: {
      storePeriod() {
        if (!this.$refs.form.validate()) {
          alert('Verifique os campos do formulário!');
          return;
        }
        this.$store.dispatch('createPeriod', {
          business: this.business.id,
          payload: this.payload
        }).then(() => {
          this.$refs.form.reset();
          this.$router.back();
        }).catch((error) => {
          if (error.response && error.response.status === 422) {
            this.formErrors = error.response.data.errors;
          }
        });
      },
      updatePeriod() {
        if (!this.$refs.form.validate()) {
          alert('Verifique os campos do formulário!');
          return;
        }
        this.$store.dispatch('updatePeriod', {
          business: this.business.id,
          period: this.$route.params.period,
          payload: this.payload
        }).then(() => {
          this.$refs.form.reset();
          this.$router.back();
        }).catch((error) => {
          if (error.response && error.response.status === 422) {
            this.formErrors = error.response.data.errors;
          }
        });
      },
    },
    computed: {
      creating() {
        return this.$route.name === 'periods_create';
      },
      updating() {
       return this.$route.name === 'periods_update';
      },
      business() {
        return this.$store.getters.getLoginAuthBusiness;
      },
    },
  };
</script>
