<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat dark color="primary">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title v-if="creating">Criar Opção</v-list-item-title>
            <v-list-item-title v-if="updating">Alterar Opção</v-list-item-title>
            <v-list-item-subtitle>Listagem dos dados</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn @click="$router.back()" icon>
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-col>
    <template v-if="payload">
      <v-col cols="12">
        <v-card flat outlined>
          <v-card-text>
            <v-form ref="form" v-model="valid">
              <v-row dense>
                <v-col cols="12">
                  <v-text-field v-model="payload.name" label="Nome*" type="text"
                                :error-messages="formErrors.name"
                                hide-details="auto" :rules="[$rules.required]"/>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="payload.min" label="Mínimo a ser escolhido*" type="number"
                                :error-messages="formErrors.min"
                                hide-details="auto" :rules="[$rules.required]"/>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="payload.max" label="Máximo a ser escolhido*" type="number"
                                :error-messages="formErrors.max"
                                hide-details="auto" :rules="[$rules.required]"/>
                </v-col>
                <v-col cols="12" md="6" v-if="!(payload.min <= 1 && payload.max <= 1)">
                  <v-switch flat inset v-model="payload.twice" label="As seleções poderão ser escolhidas mais de uma vez"
                            :error-messages="formErrors.twice"
                            hide-details="auto"/>
                </v-col>
                <v-col cols="12" md="6">
                  <v-switch flat inset v-model="payload.active" label="Visível na Loja"
                            :error-messages="formErrors.active"
                            hide-details="auto"/>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn text @click="$refs.form.reset()">Limpar</v-btn>
            <v-btn depressed color="primary" @click="storeOption()" v-if="creating">Salvar</v-btn>
            <v-btn depressed color="primary" @click="updateOption()" v-if="updating">Alterar</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  export default {
    name: 'a-option-form',
    data: () => ({
      formErrors: {},
      payload: {
        name: '',
        min: '',
        max: '',
        twice: false,
        active: true,
      },
      valid: true,
    }),
    created() {
      if (this.updating) {
        this.$store.dispatch('fetchOption', {
          business: this.business.id,
          item: this.$route.params.item,
          option: this.$route.params.option
        }).then((response) => {
          this.payload = {
            name: response.data.data.name,
            min: response.data.data.min,
            max: response.data.data.max,
            twice: response.data.data.twice,
            active: response.data.data.active,
          }
        });
      }
    },
    methods: {
      storeOption() {
        if (!this.$refs.form.validate()) {
          alert('Verifique os campos do formulário!');
          return;
        }
        this.$store.dispatch('createOption', {
          business: this.business.id,
          item: this.$route.params.item,
          payload: this.payload
        }).then(() => {
          this.$refs.form.reset();
          this.$router.back();
        }).catch((error) => {
          if (error.response && error.response.status === 422) {
            this.formErrors = error.response.data.errors;
          }
        });
      },
      updateOption() {
        if (!this.$refs.form.validate()) {
          alert('Verifique os campos do formulário!');
          return;
        }
        this.$store.dispatch('updateOption', {
          business: this.business.id,
          item: this.$route.params.item,
          option: this.$route.params.option,
          payload: this.payload
        }).then(() => {
          this.$refs.form.reset();
          this.$router.back();
        }).catch((error) => {
          if (error.response && error.response.status === 422) {
            this.formErrors = error.response.data.errors;
          }
        });
      },
    },
    computed: {
      creating() {
        return this.$route.name === 'options_create';
      },
      updating() {
       return this.$route.name === 'options_update';
      },
      business() {
        return this.$store.getters.getLoginAuthBusiness;
      },
    },
  };
</script>
