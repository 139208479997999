import resource from '../../resource';

const state = {
  payments: null,
};

const getters = {
  getPayments(state) {
    return state.payments;
  },
};

const mutations = {
  SET_PAYMENTS(state, data) {
    state.payments = data;
  },
};

const actions = {
  fetchPayments(context, { business, order }) {
    return resource.get(`/businesses/${business}/orders/${order}/payments`)
      .then((response) => {
        context.commit('SET_PAYMENTS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  fetchPayment(context, { business, order, payment }) {
    return resource.get(`/businesses/${business}/orders/${order}/payments/${payment}`)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  searchPayments(context, { business, order, search }) {
    return resource.get(`/businesses/${business}/orders/${order}/payments`, { params: { name: search } })
      .then((response) => {
        context.commit('SET_PAYMENTS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  toPaymentsPage(context, { business, order, search, page }) {
    return resource.get(`/businesses/${business}/orders/${order}/payments`, { params: { name: search, page: page } })
      .then((response) => {
        context.commit('SET_PAYMENTS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  createPayment(context, { business, order, payload }) {
    return resource.post(`/businesses/${business}/orders/${order}/payments`, payload)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  updatePayment(context, { business, order, payment, payload }) {
    return resource.put(`/businesses/${business}/orders/${order}/payments/${payment}`, payload)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  deletePayment(context, { business, order, payment }) {
    return resource.delete(`/businesses/${business}/orders/${order}/payments/${payment}`)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
