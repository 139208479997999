<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12" class="d-print-none">
      <v-btn block depressed @click="printPage">
        <v-icon left>mdi-printer</v-icon>Imprimir
      </v-btn>
    </v-col>
    <template v-if="totems">
      <v-col cols="4" v-for="totem in totems.data" :key="totem.id">
        <v-card flat outlined>
          <QRCanvas style="width: 100%" :options="{ data: `client.pointurbano.com/businesses/${business.id}?totem=${totem.number}`, padding: 8, cellSize: 8, correctLevel: 'H', logo: { image: logo } }"/>
          <v-card-text class="text-center">
            Totem Nº {{ totem.number }}
          </v-card-text>
        </v-card>
      </v-col>
    </template>
  </v-row>
</template>

<script>
import { QRCanvas } from 'qrcanvas-vue';

export default {
  name: 'a-group-list',
  components: {QRCanvas},
  data: () => ({
    logo: null,
  }),
  created() {
    this.logo = new Image();
    this.logo.src = require('../../assets/logo.png');

    this.$store.dispatch('fetchQrcodeTotems', {
      business: this.business.id,
    });
  },
  methods: {
    printPage() {
      window.print();
    },
  },
  computed: {
    totems() {
      return this.$store.getters.getQrcodeTotems;
    },
    business() {
      return this.$store.getters.getLoginAuthBusiness;
    },
  },
};
</script>
