<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat dark color="primary">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title>Opção</v-list-item-title>
            <v-list-item-subtitle>Listagem dos dados</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click="$router.push({ name: 'options_create' })">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-col>
    <template v-if="options">
      <v-col cols="12">
        <v-text-field v-model="optionsSearch" prepend-inner-icon="mdi-magnify"
                      label="Pesquisar" @keyup.native.enter="searchOptions()"/>
      </v-col>
      <v-col cols="12">
        <v-data-table
                    :headers="optionsHeaders"
                    :items="options.data"
                    :page.sync="options.meta.current_page"
                    :items-per-page.sync="options.meta.per_page"
                    hide-default-footer
                    disable-sort>
          <template v-slot:item.twice="{ item }">
            <v-simple-checkbox v-model="item.twice" disabled></v-simple-checkbox>
          </template>
          <template v-slot:item.active="{ item }">
            <v-simple-checkbox v-model="item.active" disabled/>
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn :to="{ name: 'options_update', params: { option: item.id } }" icon class="mr-0">
              <v-icon>mdi-file-document-edit-outline</v-icon>
            </v-btn>
            <v-btn :to="{ name: 'selections', params: { item: $route.params.item, option: item.id } }" icon class="mr-0">
              <v-icon>mdi-format-list-checkbox</v-icon>
            </v-btn>
            <v-btn @click="destroyOption(item)" icon class="mr-0">
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12">
        <v-pagination v-model="page" :length="options.meta.last_page" :total-visible="7"></v-pagination>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  export default {
    name: 'a-option-list',
    data: () => ({
      page: 1,
      optionsSearch: '',
      optionsHeaders: [
        { text: 'Nome', value: 'name' },
        { text: 'Mínimo', value: 'min' },
        { text: 'Máximo', value: 'max' },
        { text: 'Seleção Múltipla', value: 'twice' },
        { text: 'Visível', value: 'active' },
        { text: 'Ação', align: 'center', width: 180, value: 'action' },
      ],
    }),
    watch: {
      page(value) {
        this.$store.dispatch('toOptionsPage', {
          business: this.business.id,
          item: this.$route.params.item,
          search: this.optionsSearch,
          page: value
        });
      },
    },
    created() {
      this.fetchOptions();
    },
    methods: {
      fetchOptions() {
        this.$store.dispatch('fetchOptions', {
          business: this.business.id,
          item: this.$route.params.item,
        });
      },
      searchOptions() {
        this.$store.dispatch('searchOptions', {
          business: this.business.id,
          item: this.$route.params.item,
          search: this.optionsSearch
        });
      },
      destroyOption(option) {
        if (confirm('Tem certeza que deseja excluir esse item?')) {
          this.$store.dispatch('deleteOption', {
            business: this.business.id,
            item: this.$route.params.item,
            option: option.id
          }).then(() => {
            this.fetchOptions();
          });
        }
      },
    },
    computed: {
      options() {
        return this.$store.getters.getOptions;
      },
      business() {
        return this.$store.getters.getLoginAuthBusiness;
      },
    },
  };
</script>
