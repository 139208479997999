import ATotemList from './index'
import ATotemForm from './form'

export default [
  {
    path: '/totems/',
    component: ATotemList,
    name: 'totems',
    meta: {
     requiresAuth: true
    }
  },
  {
    path: '/totems/create',
    component: ATotemForm,
    name: 'totems_create',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/totems/:totem/update',
    component: ATotemForm,
    name: 'totems_update',
    meta: {
      requiresAuth: true
    }
  },
];
