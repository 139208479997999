import resource from '../../resource';

const state = {
  coupons: null,
};

const getters = {
  getCoupons(state) {
    return state.coupons;
  },
};

const mutations = {
  SET_COUPONS(state, data) {
    state.coupons = data;
  },
};

const actions = {
  fetchCoupons(context, { business }) {
    return resource.get(`/businesses/${business}/coupons`)
      .then((response) => {
        context.commit('SET_COUPONS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  fetchCoupon(context, { business, coupon }) {
    return resource.get(`/businesses/${business}/coupons/${coupon}`)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  searchCoupons(context, { business, search }) {
    return resource.get(`/businesses/${business}/coupons`, { params: { name: search } })
      .then((response) => {
        context.commit('SET_COUPONS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  toCouponsPage(context, { business, search, page }) {
    return resource.get(`/businesses/${business}/coupons`, { params: { name: search, page: page } })
      .then((response) => {
        context.commit('SET_COUPONS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  createCoupon(context, { business, payload }) {
    return resource.post(`/businesses/${business}/coupons`, payload)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  updateCoupon(context, { business, coupon, payload }) {
    return resource.put(`/businesses/${business}/coupons/${coupon}`, payload)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  deleteCoupon(context, { business, coupon }) {
    return resource.delete(`/businesses/${business}/coupons/${coupon}`)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
