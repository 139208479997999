import ACouponList from './index'
import ACouponForm from './form'

export default [
  {
    path: '/coupons/',
    component: ACouponList,
    name: 'coupons',
    meta: {
     requiresAuth: true
    }
  },
  {
    path: '/coupons/create',
    component: ACouponForm,
    name: 'coupons_create',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/coupons/:coupon/update',
    component: ACouponForm,
    name: 'coupons_update',
    meta: {
      requiresAuth: true
    }
  },
];
