<template>
  <v-row dense>
    <v-col cols="12">
      <h1 class="text-subtitle-1 font-weight-light text-uppercase">Garçons/Balconistas</h1>
    </v-col>
    <template v-if="businessWaiters && businessWaiters.data.length">
      <v-col cols="12" md="4" xl="2" v-for="waiter in businessWaiters.data" :key="waiter.id">
        <v-card flat outlined>
          <v-list-item two-line v-bind="$attrs">
            <v-list-item-avatar rounded size="80">
              <v-img :src="waiter.avatar ? waiter.avatar.url : require('../../assets/placeholder.png')" aspect-ratio="1">
                <v-row dense>
                  <v-col cols="12" v-if="!waiter.online" class="d-flex justify-center align-center fill-height white--text" style="background-color: rgba(0, 0, 0, .7)">
                    <span>Offline</span>
                  </v-col>
                </v-row>
              </v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ waiter.name }} - {{ waiter.online ? 'Online' : 'Offline' }}</v-list-item-title>
              <v-list-item-subtitle>{{ waiter.email }}</v-list-item-subtitle>
              <v-list-item-subtitle v-if="waiter.telephone">{{ waiter.telephone.telephone }}</v-list-item-subtitle>
              <v-list-item-subtitle v-if="waiter.toggle_online_at">
                {{ waiter.online ? 'Online' : 'Offline' }} desde {{ DateTime.fromSQL(waiter.toggle_online_at).setLocale('pt-BR').toFormat('dd LLL yyyy t') }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
    </template>
    <template v-else>
      <v-col cols="12" md="4" xl="2">
        <v-alert border="left">Não há Garçons/Balconistas Vinculados.</v-alert>
      </v-col>
    </template>
  </v-row>
</template>

<script>
import { DateTime } from "luxon";

export default {
  inheritAttrs: false,
  name: 'a-business-waiters-widget',
  data: () => ({
    DateTime: DateTime,
  }),
  created() {
    this.$store.dispatch('fetchBusinessWaiters', {
      business: this.business.id,
    });
  },
  computed: {
    businessWaiters() {
      return this.$store.getters.getBusinessWaiters;
    },
    business() {
      return this.$store.getters.getLoginAuthBusiness;
    }
  }
}
</script>
