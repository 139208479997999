import Print from './index'

export default [
  {
    path: '/print/:order',
    name: 'print',
    component: Print,
    meta: {
      requiresAuth: true
    }
  },
];