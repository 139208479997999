import resource from '../../resource';

const state = {
  thumbnails: null,
};

const getters = {
  getThumbnails(state) {
    return state.thumbnails;
  },
};

const mutations = {
  SET_THUMBNAILS(state, data) {
    state.thumbnails = data;
  },
};

const actions = {
  fetchThumbnails(context, { business }) {
    return resource.get(`/businesses/${business}/thumbnails`)
      .then((response) => {
        context.commit('SET_THUMBNAILS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  fetchThumbnail(context, { business, thumbnail }) {
    return resource.get(`/businesses/${business}/thumbnails/${thumbnail}`)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  searchThumbnails(context, { business, search }) {
    return resource.get(`/businesses/${business}/thumbnails`, { params: { name: search } })
      .then((response) => {
        context.commit('SET_THUMBNAILS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  toThumbnailsPage(context, { business, search, page }) {
    return resource.get(`/businesses/${business}/thumbnails`, { params: { name: search, page: page } })
      .then((response) => {
        context.commit('SET_THUMBNAILS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  createThumbnail(context, { business, payload }) {
    return resource.post(`/businesses/${business}/thumbnails`, payload)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  updateThumbnail(context, { business, thumbnail, payload }) {
    return resource.put(`/businesses/${business}/thumbnails/${thumbnail}`, payload)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  deleteThumbnail(context, { business, thumbnail }) {
    return resource.delete(`/businesses/${business}/thumbnails/${thumbnail}`)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
