import AThumbnailList from './index'
import AThumbnailForm from './form'

export default [
  {
    path: '/thumbnails/',
    component: AThumbnailList,
    name: 'thumbnails',
    meta: {
     requiresAuth: true
    }
  },
  {
    path: '/thumbnails/create',
    component: AThumbnailForm,
    name: 'thumbnails_create',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/thumbnails/:thumbnail/update',
    component: AThumbnailForm,
    name: 'thumbnails_update',
    meta: {
      requiresAuth: true
    }
  },
];
