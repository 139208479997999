<template>
  <v-dialog v-bind="$attrs" v-model="dialog" scrollable max-width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn outlined v-bind="attrs" v-on="on" :loading="loading">
        <v-icon left>mdi-image</v-icon> Escolher na galeria
      </v-btn>
    </template>
    <v-card flat outlined>
      <v-card-title>Galeria</v-card-title>
      <v-card-text>
        <v-item-group v-model="thumbnailSelected">
          <v-row dense>
            <v-col cols="4" md="3" v-for="thumbnail in thumbnails" :key="`thumbnail-${thumbnail.id}`">
              <v-item v-slot="{ active, toggle }" :value="thumbnail.id">
                <v-card flat @click="toggle">
                  <v-img :src="thumbnail.url" :aspect-ratio="1" contain>
                    <v-fade-transition>
                      <div v-if="active" class="d-flex justify-center align-center fill-height" style="background-color: rgba(0, 0, 0, .7)">
                        <v-icon dark class="text-xs-center" size="68">mdi-check-circle-outline</v-icon>
                      </div>
                    </v-fade-transition>
                  </v-img>
                </v-card>
              </v-item>
            </v-col>
          </v-row>
        </v-item-group>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn depressed @click="dialog = false" color="primary">Ok</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import resource from "../resource";

export default {
  inheritAttrs: false,
  name: 'a-thumbnail-search',
  props: ['value'],
  data: () => ({
    thumbnailsSearch: '',
    thumbnailSelected: null,
    thumbnails: [],
    loading: false,
    dialog: false,
  }),
  created() {
    this.loading = true;
    resource.get(`businesses/${this.business.id}/thumbnails`, { params: { all: 1 } }).then((response) => {
      this.loading = false;
      this.thumbnails = response.data.data;
    });
    if (this.value) {
      this.thumbnailSelected = this.value;
    }
  },
  watch: {
    value(value) {
      this.thumbnailSelected = value;
    },
    thumbnailSelected(value) {
      this.$emit('input', value);
    },
  },
  computed: {
    business() {
      return this.$store.getters.getLoginAuthBusiness;
    },
  },
}
</script>
