import Vue from 'vue';
import Vuex from 'vuex';

import application from "../views/Application/store.module";
import coupon from "../views/Coupon/store.module";
import dashboard from "../views/Dashboard/store.module";
import driver from "../views/Driver/store.module";
import group from "../views/Group/store.module";
import httpNotFound from "../views/HttpNotFound/store.module";
import item from "../views/Item/store.module";
import login from "../views/Login/store.module";
import option from "../views/Option/store.module";
import period from "../views/Period/store.module";
import order from "../views/Order/store.module";
import payment from "../views/Payment/store.module";
import print from "../views/Print/store.module";
import profile from "../views/Profile/store.module";
import qrcode from "../views/Qrcode/store.module";
import rating from "../views/Rating/store.module";
import selection from "../views/Selection/store.module";
import settings from "../views/Settings/store.module";
import stock from "../views/Stock/store.module";
import thirdPartyLogin from "../views/ThirdPartyLogin/store.module";
import thumbnail from "../views/Thumbnail/store.module";
import totem from "../views/Totem/store.module";
import waiter from "../views/Waiter/store.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    application,
    coupon,
    dashboard,
    driver,
    group,
    httpNotFound,
    item,
    login,
    option,
    period,
    order,
    payment,
    print,
    profile,
    qrcode,
    rating,
    selection,
    settings,
    stock,
    thirdPartyLogin,
    thumbnail,
    totem,
    waiter,
  },
})
