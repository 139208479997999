<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat dark color="primary">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title>Cupom</v-list-item-title>
            <v-list-item-subtitle>Listagem dos dados</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click="$router.push({ name: 'coupons_create' })">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-col>
    <template v-if="coupons">
      <v-col cols="12">
        <v-text-field v-model="couponsSearch" prepend-inner-icon="mdi-magnify"
                      label="Pesquisar" @keyup.native.enter="searchCoupons()"/>
      </v-col>
      <v-col cols="12">
        <v-data-table
                    :headers="couponsHeaders"
                    :items="coupons.data"
                    :page.sync="coupons.meta.current_page"
                    :items-per-page.sync="coupons.meta.per_page"
                    hide-default-footer
                    disable-sort>
          <template v-slot:item.type="{ item }">
            {{ couponType(item.type) }}
          </template>
          <template v-slot:item.value="{ item }">
            <span v-if="item.type === 'DECIMAL'">{{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.amount) }}</span>
            <span v-if="item.type === 'PERCENTAGE'">{{ item.percentage }}%</span>
          </template>
          <template v-slot:item.valid_until="{ item }">
            {{ DateTime.fromSQL(item.valid_until).setLocale('pt-BR').toFormat('dd LLL yyyy TT') }}
          </template>
          <template v-slot:item.min_amount="{ item }">
            <span v-if="item.min_amount">{{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.min_amount) }}</span>
          </template>
          <template v-slot:item.max_amount="{ item }">
            <span v-if="item.max_amount">{{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.max_amount) }}</span>
          </template>
          <template v-slot:item.active="{ item }">
            <v-simple-checkbox v-model="item.active" disabled/>
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn :to="{ name: 'coupons_update', params: { coupon: item.id } }" icon class="mr-0">
              <v-icon>mdi-file-document-edit-outline</v-icon>
            </v-btn>
            <v-btn @click="destroyCoupon(item)" icon class="mr-0">
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12">
        <v-pagination v-model="page" :length="coupons.meta.last_page" :total-visible="7"></v-pagination>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  import { DateTime } from "luxon";

  export default {
    name: 'a-coupon-list',
    data: () => ({
      page: 1,
      couponsSearch: '',
      couponsHeaders: [
        { text: 'Código', value: 'code' },
        { text: 'Tipo', value: 'type' },
        { text: 'Valor', value: 'value' },
        { text: 'Disponével', value: 'quantity' },
        { text: 'Resgatados', value: 'redeemed' },
        { text: 'Validade', value: 'valid_until' },
        { text: 'Mínimo', value: 'min_amount' },
        { text: 'Máximo', value: 'max_amount' },
        { text: 'Ativo', value: 'active' },
        { text: 'Ação', align: 'center', width: 120, value: 'action' },
      ],
      DateTime: DateTime,
    }),
    watch: {
      page(value) {
        this.$store.dispatch('toCouponsPage', {
          business: this.business.id,
          search: this.couponsSearch,
          page: value
        });
      },
    },
    created() {
      this.fetchCoupons();
    },
    methods: {
      fetchCoupons() {
        this.$store.dispatch('fetchCoupons', {
          business: this.business.id,
        });
      },
      searchCoupons() {
        this.$store.dispatch('searchCoupons', {
          business: this.business.id,
          search: this.couponsSearch
        });
      },
      destroyCoupon(coupon) {
        if (confirm('Tem certeza que deseja excluir esse item?')) {
          this.$store.dispatch('deleteCoupon', {
            business: this.business.id,
            coupon: coupon.id
          }).then(() => {
            this.fetchCoupons();
          });
        }
      },
      couponType(type) {
        switch (type) {
          case 'DECIMAL':
            return 'Valor';
          case 'PERCENTAGE':
            return 'Porcentagem';
          case 'DELIVERY_FREE':
            return 'Entrega Gratis';
          default:
            return 'Não definido';
        }
      },
    },
    computed: {
      coupons() {
        return this.$store.getters.getCoupons;
      },
      business() {
        return this.$store.getters.getLoginAuthBusiness;
      },
    },
  };
</script>
