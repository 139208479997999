<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat dark color="primary">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title>Pagamento</v-list-item-title>
            <v-list-item-subtitle>Listagem dos dados</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click="$router.push({ name: 'payments_create' })">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-col>
    <template v-if="payments">
      <v-col cols="12">
        <v-text-field v-model="paymentsSearch" prepend-inner-icon="mdi-magnify"
                      label="Pesquisar" @keyup.native.enter="searchPayments()"/>
      </v-col>
      <v-col cols="12">
        <v-data-table
                    :headers="paymentsHeaders"
                    :items="payments.data"
                    :page.sync="payments.meta.current_page"
                    :items-per-page.sync="payments.meta.per_page"
                    hide-default-footer
                    disable-sort>
          <template v-slot:item.identification="{ item }">
            #{{ item.identification }}
          </template>
          <template v-slot:item.type="{ item }">
            {{ paymentType(item.type) }} {{ item.payment_method ? `- ${item.payment_method.name}` : '' }}
          </template>
          <template v-slot:item.amount="{ item }">
            {{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.amount) }}
          </template>
          <template v-slot:item.status="{ item }">
            {{ paymentStatus(item.status) }}
          </template>
          <template v-slot:item.cash_change="{ item }">
            {{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.cash_change) }}
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn :disabled="item.type === 'IN_APP'" :to="{ name: 'payments_update', params: { payment: item.id } }" icon class="mr-0">
              <v-icon>mdi-file-document-edit-outline</v-icon>
            </v-btn>
            <v-btn :disabled="item.type === 'IN_APP'" @click="destroyPayment(item)" icon class="mr-0">
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12">
        <v-pagination v-model="page" :length="payments.meta.last_page" :total-visible="7"></v-pagination>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  export default {
    name: 'a-payment-list',
    data: () => ({
      page: 1,
      paymentsSearch: '',
      paymentsHeaders: [
        { text: 'Identificador', value: 'identification' },
        { text: 'Tipo', value: 'type' },
        { text: 'Valor', value: 'amount' },
        { text: 'Status', value: 'status' },
        { text: 'Troco', value: 'cash_change' },
        { text: 'Ação', align: 'center', width: 120, value: 'action' },
      ],
    }),
    watch: {
      page(value) {
        this.$store.dispatch('toPaymentsPage', {
          business: this.business.id,
          order: this.$route.params.order,
          search: this.paymentsSearch,
          page: value
        });
      },
    },
    created() {
      this.fetchPayments();
    },
    methods: {
      fetchPayments() {
        this.$store.dispatch('fetchPayments', {
          business: this.business.id,
          order: this.$route.params.order,
        });
      },
      searchPayments() {
        this.$store.dispatch('searchPayments', {
          business: this.business.id,
          order: this.$route.params.order,
          search: this.paymentsSearch
        });
      },
      destroyPayment(payment) {
        if (confirm('Tem certeza que deseja excluir esse item?')) {
          this.$store.dispatch('deletePayment', {
            business: this.business.id,
            order: this.$route.params.order,
            payment: payment.id
          }).then(() => {
            this.fetchPayments();
          });
        }
      },
      paymentStatus(status) {
        switch (status) {
          case 'PENDING':
            return 'Processando';
          case 'SUCCEEDED':
            return 'Aprovado';
          case 'FAILED':
            return 'Rejeitado';
          case 'CANCELLED':
            return 'Cancelado';
          case 'CASH_BACK':
            return 'Estornado';
          default:
            return 'Não definido';
        }
      },
      paymentType(type) {
        switch (type) {
          case 'IN_APP':
            return 'No APP';
          case 'CREDIT_CARD':
            return 'Cartão de Crédito';
          case 'CURRENCY':
            return 'Dinheiro';
          case 'DEBIT_CARD':
            return 'Cartão de Debito';
          case 'MEAL_TICKET':
            return 'Vale Refeição';
          default:
            return 'Não definido';
        }
      },
    },
    computed: {
      payments() {
        return this.$store.getters.getPayments;
      },
      business() {
        return this.$store.getters.getLoginAuthBusiness;
      },
    },
  };
</script>
