import resource from "@/resource";

const state = {
  totems: null
};

const getters = {
  getQrcodeTotems(state) {
    return state.totems;
  },
};

const mutations = {
  SET_QRCODE_TOTEMS(state, data) {
    state.totems = data;
  },
};

const actions = {
  fetchQrcodeTotems(context, { business }) {
    return resource.get(`/businesses/${business}/totems`)
        .then((response) => {
          context.commit('SET_QRCODE_TOTEMS', response.data);
          return Promise.resolve(response);
        }).catch(function (error) {
          return Promise.reject(error);
        });
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
