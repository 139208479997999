import resource from '../../resource';

const state = {
  selections: null,
};

const getters = {
  getSelections(state) {
    return state.selections;
  },
};

const mutations = {
  SET_SELECTIONS(state, data) {
    state.selections = data;
  },
};

const actions = {
  fetchSelections(context, { business, item, option }) {
    return resource.get(`/businesses/${business}/items/${item}/options/${option}/selections`)
      .then((response) => {
        context.commit('SET_SELECTIONS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  fetchSelection(context, { business, item, option, selection }) {
    return resource.get(`/businesses/${business}/items/${item}/options/${option}/selections/${selection}`)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  searchSelections(context, { business, item, option, search }) {
    return resource.get(`/businesses/${business}/items/${item}/options/${option}/selections`, { params: { name: search } })
      .then((response) => {
        context.commit('SET_SELECTIONS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  toSelectionsPage(context, { business, item, option, search, page }) {
    return resource.get(`/businesses/${business}/items/${item}/options/${option}/selections`, { params: { name: search, page: page } })
      .then((response) => {
        context.commit('SET_SELECTIONS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  createSelection(context, { business, item, option, payload }) {
    return resource.post(`/businesses/${business}/items/${item}/options/${option}/selections`, payload)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  updateSelection(context, { business, item, option, selection, payload }) {
    return resource.put(`/businesses/${business}/items/${item}/options/${option}/selections/${selection}`, payload)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  deleteSelection(context, { business, item, option, selection }) {
    return resource.delete(`/businesses/${business}/items/${item}/options/${option}/selections/${selection}`)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
