import resource from '../../resource';

const state = {
  periods: null,
};

const getters = {
  getPeriods(state) {
    return state.periods;
  },
};

const mutations = {
  SET_PERIODS(state, data) {
    state.periods = data;
  },
};

const actions = {
  fetchPeriods(context, { business }) {
    return resource.get(`/businesses/${business}/periods`)
      .then((response) => {
        context.commit('SET_PERIODS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  fetchPeriod(context, { business, period }) {
    return resource.get(`/businesses/${business}/periods/${period}`)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  searchPeriods(context, { business, search }) {
    return resource.get(`/businesses/${business}/periods`, { params: { name: search } })
      .then((response) => {
        context.commit('SET_PERIODS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  toPeriodsPage(context, { business, search, page }) {
    return resource.get(`/businesses/${business}/periods`, { params: { name: search, page: page } })
      .then((response) => {
        context.commit('SET_PERIODS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  createPeriod(context, { business, payload }) {
    return resource.post(`/businesses/${business}/periods`, payload)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  updatePeriod(context, { business, period, payload }) {
    return resource.put(`/businesses/${business}/periods/${period}`, payload)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  deletePeriod(context, { business, period }) {
    return resource.delete(`/businesses/${business}/periods/${period}`)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
