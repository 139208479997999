<template>
  <v-item-group v-model="groupSelected" multiple>
    <v-row dense>
      <v-col cols="12" sm="6" md="3" lg="2" xl="1" v-for="group in groups" :key="`group-${group.id}`">
        <v-item v-slot="{ active, toggle }" :value="group.id">
          <v-card flat outlined :dark="active" :color="active ? 'primary' : ''" @click="toggle">
            <v-list-item two-line>
              <v-list-item-avatar rounded>
                <v-img :src="group.thumbnail ? group.thumbnail.url : require('../assets/placeholder.png')" contain aspect-ratio="1"/>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ group.name }}</v-list-item-title>
                <v-list-item-subtitle>{{ group.description }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-item>
      </v-col>
    </v-row>
  </v-item-group>
</template>

<script>
  import resource from "../resource";

  export default {
    inheritAttrs: false,
    name: 'a-waiter-search',
    props: ['value'],
    data: () => ({
      groupsSearch: '',
      groupSelected: [],
      groups: [],
      loading: false,
    }),
    created() {
      this.loading = true;
      resource.get(`businesses/${this.business.id}/groups`, { params: { all: 1 } }).then((response) => {
        this.loading = false;
        this.groups = response.data.data;
      });
      if (this.value) {
        this.groupSelected = this.value;
      }
    },
    watch: {
      value(value) {
        this.groupSelected = value;
      },
      groupSelected(value) {
        this.$emit('input', value);
      },
    },
    computed: {
      business() {
        return this.$store.getters.getLoginAuthBusiness;
      },
    },
  }
</script>
