<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat dark color="primary">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title>Motorista</v-list-item-title>
            <v-list-item-subtitle>Listagem dos dados</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click="$router.push({ name: 'drivers_create' })">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-col>
    <template v-if="drivers">
      <v-col cols="12">
        <v-text-field v-model="driversSearch" prepend-inner-icon="mdi-magnify"
                      label="Pesquisar" @keyup.native.enter="searchDrivers()"/>
      </v-col>
      <v-col cols="12">
        <v-data-table
                    :headers="driversHeaders"
                    :items="drivers.data"
                    :page.sync="drivers.meta.current_page"
                    :items-per-page.sync="drivers.meta.per_page"
                    hide-default-footer
                    disable-sort>
          <template v-slot:item.driver_accepted="{ item }">
            <v-simple-checkbox v-model="item.driver_accepted" disabled/>
          </template>
          <template v-slot:item.vehicle="{ item }">
            {{ driverVehicle(item.vehicle) }}
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn disabled :to="{ name: 'drivers_update', params: { driver: item.id } }" icon class="mr-0">
              <v-icon>mdi-file-document-edit-outline</v-icon>
            </v-btn>
            <v-btn @click="destroyDriver(item)" icon class="mr-0">
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12">
        <v-pagination v-model="page" :length="drivers.meta.last_page" :total-visible="7"></v-pagination>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  export default {
    name: 'a-driver-list',
    data: () => ({
      page: 1,
      driversSearch: '',
      driversHeaders: [
        { text: 'Nome', value: 'name' },
        { text: 'Email', value: 'email' },
        { text: 'Veículo', value: 'vehicle' },
        { text: 'Aceito', value: 'driver_accepted' },
        { text: 'Ação', align: 'center', width: 120, value: 'action' },
      ],
    }),
    watch: {
      page(value) {
        this.$store.dispatch('toDriversPage', {
          business: this.business.id,
          search: this.driversSearch,
          page: value
        });
      },
    },
    created() {
      this.fetchDrivers();
    },
    methods: {
      fetchDrivers() {
        this.$store.dispatch('fetchDrivers', {
          business: this.business.id,
        });
      },
      searchDrivers() {
        this.$store.dispatch('searchDrivers', {
          business: this.business.id,
          search: this.driversSearch
        });
      },
      destroyDriver(driver) {
        if (confirm('Tem certeza que deseja excluir esse item?')) {
          this.$store.dispatch('deleteDriver', {
            business: this.business.id,
            driver: driver.id
          }).then(() => {
            this.fetchDrivers();
          });
        }
      },
      driverVehicle(vehicle) {
        switch (vehicle) {
          case 'CAR':
            return 'Carro';
          case 'MOTORCYCLE':
            return 'Motocicleta';
          case 'BICYCLE':
            return 'Bicicleta';
          case 'SCOOTER':
            return 'Scooter';
          default:
            return 'Não definido';
        }
      },
    },
    computed: {
      drivers() {
        return this.$store.getters.getDrivers;
      },
      business() {
        return this.$store.getters.getLoginAuthBusiness;
      },
    },
  };
</script>
