const themeKeyName = 'PUB_FRONT_THEME_DARK';
const printerColumnsKeyName = 'PUB_FRONT_PRINTER_COLUMNS';

const state = {
  themeDark: false,
  printerColumns: 35
};

const getters = {
  getThemeDark(state) {
    if(localStorage.getItem(themeKeyName) && !!localStorage.getItem(themeKeyName)){
      state.themeDark = JSON.parse(localStorage.getItem(themeKeyName));
    }
    return state.themeDark;
  },
  getPrinterColumns(state) {
    if(localStorage.getItem(printerColumnsKeyName) && !!localStorage.getItem(printerColumnsKeyName)){
      state.printerColumns = JSON.parse(localStorage.getItem(printerColumnsKeyName));
    }
    return state.printerColumns;
  },
};

const mutations = {
  SET_THEME_DARK(state, status) {
    localStorage.setItem(themeKeyName, status);
    state.themeDark = status;
  },
  SET_PRINTER_COLUMNS(state, status) {
    localStorage.setItem(printerColumnsKeyName, status);
    state.printerColumns = status;
  },
};

const actions = {
  setThemeDark(context, data) {
    context.commit('SET_THEME_DARK', data);
  },
  setPrinterColumns(context, data) {
    context.commit('SET_PRINTER_COLUMNS', data);
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
