<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat dark color="primary">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title>Seleção</v-list-item-title>
            <v-list-item-subtitle>Listagem dos dados</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click="$router.push({ name: 'selections_create' })">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-col>
    <template v-if="selections">
      <v-col cols="12">
        <v-text-field v-model="selectionsSearch" prepend-inner-icon="mdi-magnify"
                      label="Pesquisar" @keyup.native.enter="searchSelections()"/>
      </v-col>
      <v-col cols="12">
        <v-data-table
                    :headers="selectionsHeaders"
                    :items="selections.data"
                    :page.sync="selections.meta.current_page"
                    :items-per-page.sync="selections.meta.per_page"
                    hide-default-footer
                    disable-sort>
          <template v-slot:item.active="{ item }">
            <v-simple-checkbox v-model="item.active" disabled/>
          </template>
          <template v-slot:item.amount="{ item }">
            {{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.amount) }}
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn :to="{ name: 'selections_update', params: { selection: item.id } }" icon class="mr-0">
              <v-icon>mdi-file-document-edit-outline</v-icon>
            </v-btn>
            <v-btn @click="destroySelection(item)" icon class="mr-0">
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12">
        <v-pagination v-model="page" :length="selections.meta.last_page" :total-visible="7"></v-pagination>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  export default {
    name: 'a-selection-list',
    data: () => ({
      page: 1,
      selectionsSearch: '',
      selectionsHeaders: [
        { text: 'Nome', value: 'name' },
        { text: 'Descrição', value: 'description' },
        { text: 'Valor', value: 'amount' },
        { text: 'Calorias', value: 'calories' },
        { text: 'Visível', value: 'active' },
        { text: 'Ação', align: 'center', width: 120, value: 'action' },
      ],
    }),
    watch: {
      page(value) {
        this.$store.dispatch('toSelectionsPage', {
          business: this.business.id,
          item: this.$route.params.item,
          option: this.$route.params.option,
          search: this.selectionsSearch,
          page: value
        });
      },
    },
    created() {
      this.fetchSelections();
    },
    methods: {
      fetchSelections() {
        this.$store.dispatch('fetchSelections', {
          business: this.business.id,
          item: this.$route.params.item,
          option: this.$route.params.option,
        });
      },
      searchSelections() {
        this.$store.dispatch('searchSelections', {
          business: this.business.id,
          item: this.$route.params.item,
          option: this.$route.params.option,
          search: this.selectionsSearch
        });
      },
      destroySelection(selection) {
        if (confirm('Tem certeza que deseja excluir esse item?')) {
          this.$store.dispatch('deleteSelection', {
            business: this.business.id,
            item: this.$route.params.item,
            option: this.$route.params.option,
            selection: selection.id
          }).then(() => {
            this.fetchSelections();
          });
        }
      },
    },
    computed: {
      selections() {
        return this.$store.getters.getSelections;
      },
      business() {
        return this.$store.getters.getLoginAuthBusiness;
      },
    },
  };
</script>
