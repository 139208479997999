<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat dark :color="serviceColor($route.query.service)">
        <v-img max-width="500" :src="serviceImage($route.query.service)"/>
      </v-card>
    </v-col>
    <template v-if="orders">
      <v-col cols="12">
        <v-text-field v-model="ordersSearch" prepend-inner-icon="mdi-magnify"
                      label="Pesquisar" @keyup.native.enter="searchOrders()"/>
      </v-col>
      <v-col cols="12">
        <v-data-iterator
                    :items="orders.data"
                    :page.sync="orders.meta.current_page"
                    :items-per-page.sync="orders.meta.per_page"
                    hide-default-footer
                    disable-sort>
          <template v-slot:default="props">
            <v-row dense>
              <v-col cols="12" md="6" lg="4" xl="3" v-for="item in props.items" :key="item.id">
                <a-order-tile :order="item"/>
              </v-col>
            </v-row>
          </template>
        </v-data-iterator>
      </v-col>
      <v-col cols="12">
        <v-pagination v-model="page" :length="orders.meta.last_page" :total-visible="7"></v-pagination>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  import AOrderTile from "@/components/AOrderTile";
  export default {
    name: 'a-order-list',
    components: {AOrderTile},
    data: () => ({
      page: 1,
      ordersSearch: '',
    }),
    watch: {
      page(value) {
        this.$store.dispatch('toOrdersPage', {
          business: this.business.id,
          service: this.$route.query.service,
          search: this.ordersSearch,
          page: value
        });
      },
    },
    created() {
      this.fetchOrders();
    },
    methods: {
      fetchOrders() {
        this.$store.dispatch('fetchOrders', {
          business: this.business.id,
          service: this.$route.query.service,
        });
      },
      searchOrders() {
        this.$store.dispatch('searchOrders', {
          business: this.business.id,
          service: this.$route.query.service,
          search: this.ordersSearch
        });
      },
      destroyOrder(order) {
        if (confirm('Tem certeza que deseja excluir esse item?')) {
          this.$store.dispatch('deleteOrder', {
            business: this.business.id,
            service: this.$route.query.service,
            order: order.id
          }).then(() => {
            this.fetchOrders();
          });
        }
      },
      serviceColor(service) {
        switch (service) {
          case 'DELIVERY':
            return '#572E82';
          case 'PICK_UP':
            return '#7C222C';
          case 'DINE_IN':
            return '#D87B03';
          default:
            return '#D32F2F';
        }
      },
      serviceImage(service) {
        switch (service) {
          case 'DELIVERY':
            return require('../../assets/banner_delivery.png');
          case 'PICK_UP':
            return require('../../assets/banner_pickup.png');
          case 'DINE_IN':
            return require('../../assets/banner_dinein.png');
          default:
            return require('../../assets/placeholder.png');
        }
      },
      serviceName(service) {
        switch (service) {
          case 'DELIVERY':
            return 'Delivery';
          case 'PICK_UP':
            return 'Retirada';
          case 'DINE_IN':
            return 'Em Mesa';
          default:
            return 'Listagem dos dados';
        }
      },
    },
    computed: {
      orders() {
        return this.$store.getters.getOrders;
      },
      business() {
        return this.$store.getters.getLoginAuthBusiness;
      },
    },
  };
</script>
