import resource from "../../resource";

const state = {
  waiters: null,
  drivers: null,
};

const getters = {
  getBusinessWaiters(state) {
    return state.waiters;
  },
  getBusinessDrivers(state) {
    return state.drivers;
  },
};

const mutations = {
  SET_BUSINESS_WAITERS(state, data) {
    state.waiters = data;
  },
  SET_BUSINESS_DRIVERS(state, data) {
    state.drivers = data;
  },
};

const actions = {
  fetchBusinessWaiters(context, { business }) {
    return resource.get(`/businesses/${business}/waiters`, { params: { all: 1 } })
      .then((response) => {
        context.commit('SET_BUSINESS_WAITERS', response.data);
        return Promise.resolve(response);
      }).catch(function (error) {
        return Promise.reject(error);
      });
  },
  fetchBusinessDrivers(context, { business }) {
    return resource.get(`/businesses/${business}/drivers`, { params: { all: 1 } })
      .then((response) => {
        context.commit('SET_BUSINESS_DRIVERS', response.data);
        return Promise.resolve(response);
      }).catch(function (error) {
        return Promise.reject(error);
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
