import ASelectionList from './index'
import ASelectionForm from './form'

export default [
  {
    path: '/items/:item/options/:option/selections/',
    component: ASelectionList,
    name: 'selections',
    meta: {
     requiresAuth: true
    }
  },
  {
    path: '/items/:item/options/:option/selections/create',
    component: ASelectionForm,
    name: 'selections_create',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/items/:item/options/:option/selections/:selection/update',
    component: ASelectionForm,
    name: 'selections_update',
    meta: {
      requiresAuth: true
    }
  },
];
