import AGroupList from './index'
import AGroupForm from './form'

export default [
  {
    path: '/groups/',
    component: AGroupList,
    name: 'groups',
    meta: {
     requiresAuth: true
    }
  },
  {
    path: '/groups/create',
    component: AGroupForm,
    name: 'groups_create',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/groups/:group/update',
    component: AGroupForm,
    name: 'groups_update',
    meta: {
      requiresAuth: true
    }
  },
];
