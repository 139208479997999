import resource from '../../resource';

const state = {
  totems: null,
};

const getters = {
  getTotems(state) {
    return state.totems;
  },
};

const mutations = {
  SET_TOTEMS(state, data) {
    state.totems = data;
  },
};

const actions = {
  fetchTotems(context, { business }) {
    return resource.get(`/businesses/${business}/totems`)
      .then((response) => {
        context.commit('SET_TOTEMS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  fetchTotem(context, { business, totem }) {
    return resource.get(`/businesses/${business}/totems/${totem}`)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  searchTotems(context, { business, search }) {
    return resource.get(`/businesses/${business}/totems`, { params: { name: search } })
      .then((response) => {
        context.commit('SET_TOTEMS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  toTotemsPage(context, { business, search, page }) {
    return resource.get(`/businesses/${business}/totems`, { params: { name: search, page: page } })
      .then((response) => {
        context.commit('SET_TOTEMS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  createTotem(context, { business, payload }) {
    return resource.post(`/businesses/${business}/totems`, payload)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  updateTotem(context, { business, totem, payload }) {
    return resource.put(`/businesses/${business}/totems/${totem}`, payload)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  deleteTotem(context, { business, totem }) {
    return resource.delete(`/businesses/${business}/totems/${totem}`)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
