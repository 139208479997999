import AOptionList from './index'
import AOptionForm from './form'

export default [
  {
    path: '/items/:item/options/',
    component: AOptionList,
    name: 'options',
    meta: {
     requiresAuth: true
    }
  },
  {
    path: '/items/:item/options/create',
    component: AOptionForm,
    name: 'options_create',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/items/:item/options/:option/update',
    component: AOptionForm,
    name: 'options_update',
    meta: {
      requiresAuth: true
    }
  },
];
