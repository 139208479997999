<template>
  <v-card flat outlined>
    <v-card-text v-if="payload">
      <v-switch v-model="payload.online" color="success" @change="updateProfile" inset
                :label="`Você se encontra ${payload.online ? 'Online' : 'Offline'} para receber pedidos`"/>
      <span class="d-block caption" v-if="business && business.toggle_online_at">
        {{ business.online ? 'Online' : 'Offline' }} desde {{ DateTime.fromSQL(business.toggle_online_at).setLocale('pt-BR').toFormat('dd LLL yyyy t') }}
      </span>
      <span class="d-block caption">
        Antes de ficar online certifique-se de estar disponível para receber pedidos de entregas!
      </span>
    </v-card-text>
    <v-skeleton-loader type="article" v-else/>
  </v-card>
</template>

<script>
import { DateTime } from "luxon";

export default {
  name: 'a-profile-online-switch',
  data: () => ({
    formErrors: {},
    dialog: false,
    payload: null,
    DateTime: DateTime,
    valid: true,
  }),
  created() {
    this.$store.dispatch('fetchProfile', {
      business: this.business.id
    }).then((response) => {
      this.payload = {
        online: response.data.data.online,
      }
    });
  },
  methods: {
    updateProfile() {
      this.$store.dispatch('updateProfile', {
        business: this.business.id,
        payload: this.payload
      }).then((response) => {
        this.$store.dispatch('loginSetBusiness', response.data.data);
      }).catch((error) => {
        if(error.response && error.response.status === 422){
          this.formErrors = error.response.data.errors;
        }
      });
    },
  },
  computed: {
    business() {
      return this.$store.getters.getLoginAuthBusiness;
    },
  }
};
</script>
