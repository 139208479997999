<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat dark color="primary">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title>Garçom</v-list-item-title>
            <v-list-item-subtitle>Listagem dos dados</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click="$router.push({ name: 'waiters_create' })">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-col>
    <template v-if="waiters">
      <v-col cols="12">
        <v-text-field v-model="waitersSearch" prepend-inner-icon="mdi-magnify"
                      label="Pesquisar" @keyup.native.enter="searchWaiters()"/>
      </v-col>
      <v-col cols="12">
        <v-data-table
                    :headers="waitersHeaders"
                    :items="waiters.data"
                    :page.sync="waiters.meta.current_page"
                    :items-per-page.sync="waiters.meta.per_page"
                    hide-default-footer
                    disable-sort>
          <template v-slot:item.business_accepted="{ item }">
            <v-simple-checkbox v-model="item.business_accepted" disabled/>
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn :to="{ name: 'waiters_update', params: { waiter: item.id } }" icon class="mr-0">
              <v-icon>mdi-file-document-edit-outline</v-icon>
            </v-btn>
            <v-btn @click="destroyWaiter(item)" icon class="mr-0">
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12">
        <v-pagination v-model="page" :length="waiters.meta.last_page" :total-visible="7"></v-pagination>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  export default {
    name: 'a-waiter-list',
    data: () => ({
      page: 1,
      waitersSearch: '',
      waitersHeaders: [
        { text: 'Nome', value: 'name' },
        { text: 'Email', value: 'email' },
        { text: 'Aceito', value: 'business_accepted' },
        { text: 'Ação', align: 'center', width: 120, value: 'action' },
      ],
    }),
    watch: {
      page(value) {
        this.$store.dispatch('toWaitersPage', {
          business: this.business.id,
          search: this.waitersSearch,
          page: value
        });
      },
    },
    created() {
      this.fetchWaiters();
    },
    methods: {
      fetchWaiters() {
        this.$store.dispatch('fetchWaiters', {
          business: this.business.id,
        });
      },
      searchWaiters() {
        this.$store.dispatch('searchWaiters', {
          business: this.business.id,
          search: this.waitersSearch
        });
      },
      destroyWaiter(waiter) {
        if (confirm('Tem certeza que deseja excluir esse item?')) {
          this.$store.dispatch('deleteWaiter', {
            business: this.business.id,
            waiter: waiter.id
          }).then(() => {
            this.fetchWaiters();
          });
        }
      },
    },
    computed: {
      waiters() {
        return this.$store.getters.getWaiters;
      },
      business() {
        return this.$store.getters.getLoginAuthBusiness;
      },
    },
  };
</script>
