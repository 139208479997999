import AItemList from './index'
import AItemForm from './form'

export default [
  {
    path: '/items/',
    component: AItemList,
    name: 'items',
    meta: {
     requiresAuth: true
    }
  },
  {
    path: '/items/create',
    component: AItemForm,
    name: 'items_create',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/items/:item/update',
    component: AItemForm,
    name: 'items_update',
    meta: {
      requiresAuth: true
    }
  },
];
