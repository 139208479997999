import Vue from 'vue'
import store from '../store';
import Router from 'vue-router'

import application from "../views/Application/router";
import cookies from "../views/Cookies/router";
import coupon from "../views/Coupon/router";
import dashboard from "../views/Dashboard/router";
import driver from "../views/Driver/router";
import emailAlert from "../views/EmailAlert/router";
import emailConfirm from "../views/EmailConfirm/router";
import group from "../views/Group/router";
import httpNotFound from "../views/HttpNotFound/router";
import item from "../views/Item/router";
import login from "../views/Login/router";
import message from "../views/Message/router";
import option from "../views/Option/router";
import period from "../views/Period/router";
import policies from "../views/Policies/router";
import order from "../views/Order/router";
import payment from "../views/Payment/router";
import print from "../views/Print/router";
import profile from "../views/Profile/router";
import qrcode from "../views/Qrcode/router";
import rating from "../views/Rating/router";
import selection from "../views/Selection/router";
import settings from "../views/Settings/router";
import stock from "../views/Stock/router";
import terms from "../views/Terms/router";
import thirdPartyLogin from "../views/ThirdPartyLogin/router";
import thumbnail from "../views/Thumbnail/router";
import totem from "../views/Totem/router";
import waiter from "../views/Waiter/router";


Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: { name: 'dashboard' }
    },
    ...application,
    ...cookies,
    ...coupon,
    ...dashboard,
    ...driver,
    ...emailAlert,
    ...emailConfirm,
    ...group,
    ...httpNotFound,
    ...item,
    ...login,
    ...message,
    ...option,
    ...period,
    ...policies,
    ...order,
    ...payment,
    ...print,
    ...profile,
    ...qrcode,
    ...rating,
    ...selection,
    ...settings,
    ...stock,
    ...terms,
    ...thirdPartyLogin,
    ...thumbnail,
    ...totem,
    ...waiter,
    {
      path: '*',
      name: 'not-found',
      redirect: { name: 'http_not_found' },
    },
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
});

router.beforeEach((to, from, next) => {
  let authCheck = store.getters.getLoginAuthCheck;
  let auth = to.matched.some(record => record.meta.requiresAuth);

  if (auth && !authCheck) {
    next({ name: 'login' })
  } else {
    next();
  }
});

export default router;
