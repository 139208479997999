import AOrderList from './index'
import AOrderForm from './form'

export default [
  {
    path: '/orders/',
    component: AOrderList,
    name: 'orders',
    meta: {
     requiresAuth: true
    }
  },
  {
    path: '/orders/create',
    component: AOrderForm,
    name: 'orders_create',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/orders/:order/update',
    component: AOrderForm,
    name: 'orders_update',
    meta: {
      requiresAuth: true
    }
  },
];
