import Qrcode from './index'

export default [
  {
    path: '/qrcode',
    name: 'qrcode',
    component: Qrcode,
    meta: {
      requiresAuth: true
    }
  },
];
