<template>
  <v-img :src="url.createObjectURL(file)" :aspect-ratio="1" :contain="false">
    <v-expand-transition>
      <div class="d-flex justify-center align-center fill-height" style="background-color: rgba(0, 0, 0, .7)">
        <v-icon dark size="68" v-if="error">mdi-close-circle-outline</v-icon>
        <v-progress-circular color="white" size="68" :value="upload" v-else>{{ upload }}</v-progress-circular>
      </div>
    </v-expand-transition>
  </v-img>
</template>

<script>
import resource from "../resource";

export default {
  name: 'a-image-upload',
  props: {
    file:  File,
  },
  data: () => ({
    error: false,
    upload: 0,
    url: window.URL,
  }),
  created() {
    const formData = new FormData();
    formData.append('file', this.file);

    resource.post(`/businesses/${this.business.id}/thumbnails`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress: (event) => { this.upload = Math.round((event.loaded * 100) / event.total) },
    }).then((response) => {
      return Promise.resolve(response);
    }).catch(() => {
      this.error = true;
    });
  },
  computed: {
    business() {
      return this.$store.getters.getLoginAuthBusiness;
    },
  },
}
</script>
