<template>
  <v-row dense align-content="start" justify="center" no-gutters class="fill-height" style="width: 100%;">
    <template v-if="cookies">
      <v-col cols="12" lg="8" v-for="cookie in cookies.data" :key="`cookie-${cookie.id}`">
        <v-card flat outlined>
          <v-card-title>
            {{ cookie.title }}
          </v-card-title>
          <v-card-subtitle>
            Versão: {{ cookie.version }} Data: {{ DateTime.fromSQL(cookie.created_at).setLocale('pt-BR').toFormat('dd LLL yyyy t') }}
          </v-card-subtitle>
          <v-card-text v-html="cookie.content"/>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-pagination v-model="params.page" :length="cookies.meta.last_page" :total-visible="7" @input="fetchCookies()"/>
      </v-col>
    </template>
  </v-row>
</template>

<script>
import { DateTime } from "luxon";

export default {
  name: 'a-cookies',
  data: () => ({
    params: {
      page: 1,
      search: '',
      limit: 1
    },
    cookies: null,
    DateTime: DateTime,
  }),
  async created() {
    await this.fetchCookies();
  },
  methods: {
    async fetchCookies() {
      const cookies = await this.$axios.get(`/cookies`, { params: this.params })
      this.cookies = cookies.data;
    },
  },
};
</script>
