import AMessage from './index'

export default [
  {
    path: '/orders/:order/message',
    name: 'message',
    component: AMessage,
    meta: {
      requiresAuth: true
    }
  },
];
