<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat dark color="primary">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title v-if="creating">Criar Pagamento</v-list-item-title>
            <v-list-item-title v-if="updating">Alterar Pagamento</v-list-item-title>
            <v-list-item-subtitle>Listagem dos dados</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn @click="$router.back()" icon>
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-col>
    <template v-if="payload">
      <v-col cols="12">
        <v-card flat outlined>
          <v-card-text>
            <v-form ref="form" v-model="valid">
              <v-row dense>
                <v-col cols="12" md="4">
                  <v-select v-model="payload.type" :items="selectTypeOptions" label="Tipo do pagamento"
                            :error-messages="formErrors.type"
                            hide-details="auto" :rules="[$rules.required]"/>
                </v-col>
                <v-col cols="12" md="4">
                  <a-payment-method-search v-model="payload.payment_method_id" :type="payload.type" label="Método"
                                           :error-messages="formErrors.payment_method_id"
                                           hide-details="auto" :rules="[$rules.required]"/>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field v-model="payload.amount" label="Valor*" prefix="R$:" type="number"
                                :error-messages="formErrors.amount"
                                hide-details="auto" :rules="[$rules.required]"/>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select v-model="payload.status" :items="selectStatusOptions" label="Status"
                            :error-messages="formErrors.status"
                            hide-details="auto" :rules="[$rules.required]"/>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field :disabled="payload.type !== 'CURRENCY'" v-model="payload.cash_change"
                                label="Troco para" type="number"
                                :error-messages="formErrors.cash_change"
                                hide-details="auto"/>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn text @click="$refs.form.reset()">Limpar</v-btn>
            <v-btn depressed color="primary" @click="storePayment()" v-if="creating">Salvar</v-btn>
            <v-btn depressed color="primary" @click="updatePayment()" v-if="updating">Alterar</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  import APaymentMethodSearch from "@/components/APaymentMethodSearch";
  export default {
    name: 'a-payment-form',
    components: {APaymentMethodSearch},
    data: () => ({
      formErrors: {},
      payload: {
        type: '',
        amount: '',
        status: '',
        cash_change: '',
        payment_method_id: ''
      },
      selectTypeOptions: [
        { text: 'Aplicativo', value: 'IN_APP', disabled: true },
        { text: 'Cartão de Crédito', value: 'CREDIT_CARD' },
        { text: 'Dinheiro', value: 'CURRENCY' },
        { text: 'Cartão de Debito', value: 'DEBIT_CARD' },
        { text: 'Vale Refeição', value: 'MEAL_TICKET' },
      ],
      selectStatusOptions: [
        { text: 'Pendente', value: 'PENDING' },
        { text: 'Pago', value: 'SUCCEEDED' },
        { text: 'Falhou', value: 'FAILED', disabled: true },
        { text: 'Cancelado', value: 'CANCELLED', disabled: true },
        { text: 'Estornado', value: 'CASH_BACK', disabled: true },
      ],
      valid: true,
    }),
    created() {
      if (this.updating) {
        this.$store.dispatch('fetchPayment', {
          business: this.business.id,
          order: this.$route.params.order,
          payment: this.$route.params.payment
        }).then((response) => {
          this.payload = {
            type: response.data.data.type,
            amount: response.data.data.amount,
            status: response.data.data.status,
            cash_change: response.data.data.cash_change,
            payment_method_id: response.data.data.payment_method ? response.data.data.payment_method.id : '',
          }
        });
      }
    },
    methods: {
      storePayment() {
        if (!this.$refs.form.validate()) {
          alert('Verifique os campos do formulário!');
          return;
        }
        this.$store.dispatch('createPayment', {
          business: this.business.id,
          order: this.$route.params.order,
          payload: this.payload
        }).then(() => {
          this.$refs.form.reset();
          this.$router.back();
        }).catch((error) => {
          if (error.response && error.response.status === 422) {
            this.formErrors = error.response.data.errors;
          }
        });
      },
      updatePayment() {
        if (!this.$refs.form.validate()) {
          alert('Verifique os campos do formulário!');
          return;
        }
        this.$store.dispatch('updatePayment', {
          business: this.business.id,
          order: this.$route.params.order,
          payment: this.$route.params.payment,
          payload: this.payload
        }).then(() => {
          this.$refs.form.reset();
          this.$router.back();
        }).catch((error) => {
          if (error.response && error.response.status === 422) {
            this.formErrors = error.response.data.errors;
          }
        });
      },
    },
    computed: {
      creating() {
        return this.$route.name === 'payments_create';
      },
      updating() {
       return this.$route.name === 'payments_update';
      },
      business() {
        return this.$store.getters.getLoginAuthBusiness;
      },
    },
  };
</script>
