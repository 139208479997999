<template>
  <v-app>
    <!--Navigation-->
    <v-navigation-drawer v-if="authCheck && !printCheck" v-model="drawer" clipped fixed app>
      <template v-slot:prepend>
        <v-list-item two-line v-if="business">
          <v-list-item-avatar rounded>
            <v-img :src="business.avatar ? business.avatar.url : require('./assets/placeholder.png')"/>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ business.name }}</v-list-item-title>
            <v-list-item-subtitle :class="business.online ? 'green--text' : 'red--text'">{{ business.online ? 'Online' : 'Offline' }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>

      <v-divider/>

      <v-list nav dense>
        <v-list-item :to="{ name: 'dashboard' }">
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Início</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'ratings' }">
          <v-list-item-icon>
            <v-icon>mdi-account-star</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Avaliações</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'thumbnails' }">
          <v-list-item-icon>
            <v-icon>mdi-folder-image</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Imágens</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'groups' }">
          <v-list-item-icon>
            <v-icon>mdi-buffer</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Grupos</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'items' }">
          <v-list-item-icon>
            <v-icon>mdi-barcode-scan</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Itens</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'coupons' }">
          <v-list-item-icon>
            <v-icon>mdi-ticket-percent</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Cupons</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'totems' }">
          <v-list-item-icon>
            <v-icon>mdi-qrcode</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Totems</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'periods' }">
          <v-list-item-icon>
            <v-icon>mdi-timer</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Períodos</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'drivers' }">
          <v-list-item-icon>
            <v-icon>mdi-motorbike</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Motoristas</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'waiters' }">
          <v-list-item-icon>
            <v-icon>mdi-room-service</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Garçons</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'profile' }">
          <v-list-item-icon>
            <v-icon>mdi-account-circle</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Perfil</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'settings' }">
          <v-list-item-icon>
            <v-icon>mdi-wrench</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Configuração</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <v-row class="pa-3" dense>
          <v-col>
            <v-btn depressed block @click="logout">Sair</v-btn>
          </v-col>
        </v-row>
      </template>
    </v-navigation-drawer>
    <!--App Bar-->
    <v-app-bar v-if="authCheck && !printCheck" dark app fixed clipped-left color="primary">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"/>
      <v-toolbar-title class="headline text-uppercase">
        <router-link :to="{ name: 'dashboard', params: { status: 'PENDING' } }">
          <span class="white--text">POINT</span>
          <span class="white--text font-weight-light">URBANO</span>
          <span class="white--text body-2">{{ appName }}</span>
        </router-link>
      </v-toolbar-title>
    </v-app-bar>
    <!--Content-->
    <v-main :class="{ 'login-back': routesTheme.includes($route.name), 'chat': routesChatTheme.includes($route.name)}">
      <template v-if="authCheck && (!geolocationPermission || !notificationsPermission)">
        <v-banner dark color="warning" single-line icon="mdi-alert" app>
          Ative a geolocalização e as notificações.
        </v-banner>
      </template>
      <v-container fluid class="fill-height">
        <router-view/>
      </v-container>
    </v-main>
    <!--Loading Dialog-->
    <v-overlay :value="loadingDialog" opacity="0">
      <v-card color="primary" class="pa-1" width="65">
        <v-img :src="require('./assets/loading.svg')" contain/>
      </v-card>
    </v-overlay>
    <!--Show OrderDialog-->
    <v-dialog v-model="showOrderDialog" persistent max-width="300">
      <v-card v-if="orderDialogPayload">
        <template v-if="orderDialogPayload.status === 'PENDING'">
          <v-img :src="require('./assets/order_pending_business.png')" :aspect-ratio="21/9"/>
          <v-card-title>Novo Pedido</v-card-title>
          <v-card-subtitle>#{{orderDialogPayload.identification}}</v-card-subtitle>
          <v-card-text>Tem pedido esperando confirmação!</v-card-text>
        </template>
        <template v-if="orderDialogPayload.status === 'CANCELLED'">
          <v-img :src="require('./assets/order_cancelled.png')" :aspect-ratio="21/9"/>
          <v-card-title>Pedido Cancelado</v-card-title>
          <v-card-subtitle>#{{orderDialogPayload.identification}}</v-card-subtitle>
          <v-card-text>Oops, o pedido foi cancelado!</v-card-text>
        </template>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="showOrderDialog = false">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import resource from "@/resource";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import Echo from "laravel-echo"
import Pusher from 'pusher-js';
window.Pusher = Pusher;

export default {
  name: 'Home',
  data: () => ({
    routesTheme: ['login', 'register', 'reset_password'],
    routesChatTheme: ['message'],
    geolocationPermission: true,
    notificationsPermission: true,
  }),
  watch: {
    themeDark(value) {
      this.$vuetify.theme.dark = value;
    },
    authCheck() {
      this.permissions();
      this.firebase();
      // this.echo();
    }
  },
  created() {
    this.$vuetify.theme.dark = this.themeDark;
    if (this.authCheck) {
      this.$store.dispatch('loginFetchAuthBusiness');
      this.permissions();
      this.firebase();
      // this.echo();
    }
  },
  methods: {
    echo() {
      if (!this.echoServer && this.authCheck) {
        this.echoServer = new Echo({
          broadcaster: 'pusher',
          key: process.env.VUE_APP_WEBSOCKET_KEY,
          wsHost: process.env.VUE_APP_WEBSOCKET_HOST,
          wsPort: process.env.VUE_APP_WEBSOCKET_PORT,
          wssPort: process.env.VUE_APP_WEBSOCKET_PORT,
          forceTLS: false,
          encrypted: true,
          disableStats: true,
          enabledTransports: ['ws', 'wss'],
          authEndpoint: `${process.env.VUE_APP_HOST}/broadcasting/auth`,
          auth: { headers: { 'Authorization': `Bearer ${this.loginToken}` } }
        });

        this.echoServer.private(`App.Models.Business.${this.business.id}`)
          .notification((notification) => {
            if (notification.type === 'broadcast.order.status' && ['PENDING', 'CANCELLED'].includes(notification.status)) {
              this.$store.dispatch('setShowOrderDialogStatus', false);
              this.$store.dispatch('setOrderDialogPayload', notification);
              this.$store.dispatch('setShowOrderDialogStatus', true);
              this.$audio.play();
            }
          });
      }
    },
    firebase() {
      if (!this.firebaseMessaging) {
        const app = initializeApp(this.firebaseConfig);
        this.firebaseMessaging = getMessaging(app);
        getToken(this.firebaseMessaging,{ vapidKey: process.env.VUE_APP_FIREBASE_VAPID_KEY }).then((currentToken) => {
          resource.post(`/auth/business/message_token`, { token: currentToken });
        });
      }
    },
    permissions() {
      navigator.permissions.query({ name: 'geolocation' }).then((result) => {
        if (result.state === 'granted' || result.state === 'prompt') {
          this.geolocationPermission = true;
        } else if (result.state === 'denied') {
          this.geolocationPermission = false;
        }
      });
      navigator.permissions.query({ name: 'notifications' }).then((result) => {
        if (result.state === 'granted' || result.state === 'prompt') {
          this.notificationsPermission = true;
        } else if (result.state === 'denied') {
          this.notificationsPermission = false;
        }
      });
    },
    logout() {
      if (confirm('Tem certeza que deseja sair?')) {
        this.$store.dispatch('loginRemoveSession')
          .finally(() => {
            this.$store.dispatch('setDrawerStatus', null);
            this.$router.push({ name: 'login' });
          });
      }
    }
  },
  computed: {
    appName() {
      return this.$store.getters.getAppName;
    },
    themeDark() {
      return this.$store.getters.getThemeDark;
    },
    firebaseMessaging: {
      get: function () {
        return this.$store.getters.getFirebaseMessaging;
      },
      set: function (status) {
        this.$store.dispatch('setFirebaseMessaging', status);
      },
    },
    echoServer: {
      get: function () {
        return this.$store.getters.getEchoServer;
      },
      set: function (status) {
        this.$store.dispatch('setEchoServer', status);
      },
    },
    drawer: {
      get: function () {
        return this.$store.getters.getDrawerStatus;
      },
      set: function (status) {
        this.$store.dispatch('setDrawerStatus', status);
      }
    },
    loadingDialog: {
      get: function () {
        return this.$store.getters.getLoadingDialog;
      },
      set: function (status) {
        this.$store.dispatch('setLoadingDialogStatus', status);
      }
    },
    showOrderDialog: {
      get: function () {
        return this.$store.getters.getShowOrderDialog;
      },
      set: function (status) {
        this.$store.dispatch('setShowOrderDialogStatus', status);
      }
    },
    orderDialogPayload() {
      return this.$store.getters.getOrderDialogPayload;
    },
    business() {
      return this.$store.getters.getLoginAuthBusiness;
    },
    authCheck() {
      return this.$store.getters.getLoginAuthCheck;
    },
    firebaseConfig() {
      return this.$store.getters.getFirebaseConfig;
    },
    loginToken() {
      return this.$store.getters.getLoginToken;
    },
    printCheck() {
      return this.$route.name === 'print' || this.$route.name === 'qrcode';
    },
  }
}
</script>
