import resource from '../../resource';

const state = {
  stocks: null,
};

const getters = {
  getStocks(state) {
    return state.stocks;
  },
};

const mutations = {
  SET_STOCKS(state, data) {
    state.stocks = data;
  },
};

const actions = {
  fetchStocks(context, { business, item }) {
    return resource.get(`/businesses/${business}/items/${item}/stocks`)
      .then((response) => {
        context.commit('SET_STOCKS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  fetchStock(context, { business, item, stock }) {
    return resource.get(`/businesses/${business}/items/${item}/stocks/${stock}`)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  searchStocks(context, { business, item, search }) {
    return resource.get(`/businesses/${business}/items/${item}/stocks`, { params: { name: search } })
      .then((response) => {
        context.commit('SET_STOCKS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  toStocksPage(context, { business, item, search, page }) {
    return resource.get(`/businesses/${business}/items/${item}/stocks`, { params: { name: search, page: page } })
      .then((response) => {
        context.commit('SET_STOCKS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  createStock(context, { business, item, payload }) {
    return resource.post(`/businesses/${business}/items/${item}/stocks`, payload)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  updateStock(context, { business, item, stock, payload }) {
    return resource.put(`/businesses/${business}/items/${item}/stocks/${stock}`, payload)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  deleteStock(context, { business, item, stock }) {
    return resource.delete(`/businesses/${business}/items/${item}/stocks/${stock}`)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
