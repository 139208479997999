<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat dark color="primary">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title>Período</v-list-item-title>
            <v-list-item-subtitle>Listagem dos dados</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click="$router.push({ name: 'periods_create' })">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-col>
    <template v-if="periods">
      <v-col cols="12">
        <v-text-field v-model="periodsSearch" prepend-inner-icon="mdi-magnify"
                      label="Pesquisar" @keyup.native.enter="searchPeriods()"/>
      </v-col>
      <v-col cols="12">
        <v-data-table
                    :headers="periodsHeaders"
                    :items="periods.data"
                    :page.sync="periods.meta.current_page"
                    :items-per-page.sync="periods.meta.per_page"
                    hide-default-footer
                    disable-sort>
          <template v-slot:item.day="{ item }">
            {{ periodDays(item.day) }}
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn :to="{ name: 'periods_update', params: { period: item.id } }" icon class="mr-0">
              <v-icon>mdi-file-document-edit-outline</v-icon>
            </v-btn>
            <v-btn @click="destroyPeriod(item)" icon class="mr-0">
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12">
        <v-pagination v-model="page" :length="periods.meta.last_page" :total-visible="7"></v-pagination>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  export default {
    name: 'a-period-list',
    data: () => ({
      page: 1,
      periodsSearch: '',
      periodsHeaders: [
        { text: 'Dia', value: 'day' },
        { text: 'Hora Início', value: 'time_start' },
        { text: 'Hora Fim', value: 'time_end' },
        { text: 'Ação', align: 'center', width: 120, value: 'action' },
      ],
    }),
    watch: {
      page(value) {
        this.$store.dispatch('toPeriodsPage', {
          business: this.business.id,
          search: this.periodsSearch,
          page: value
        });
      },
    },
    created() {
      this.fetchPeriods();
    },
    methods: {
      fetchPeriods() {
        this.$store.dispatch('fetchPeriods', {
          business: this.business.id,
        });
      },
      searchPeriods() {
        this.$store.dispatch('searchPeriods', {
          business: this.business.id,
          search: this.periodsSearch
        });
      },
      destroyPeriod(period) {
        if (confirm('Tem certeza que deseja excluir esse item?')) {
          this.$store.dispatch('deletePeriod', {
            business: this.business.id,
            period: period.id
          }).then(() => {
            this.fetchPeriods();
          });
        }
      },
      periodDays(day) {
        switch (day) {
          case 1:
            return 'Domingo';
          case 2:
            return 'Segunda';
          case 3:
            return 'Terça';
          case 4:
            return 'Quarta';
          case 5:
            return 'Quinta';
          case 6:
            return 'Sexta';
          case 7:
            return 'Sábado';
          default:
            return 'Indefinido';
        }
      },
    },
    computed: {
      periods() {
        return this.$store.getters.getPeriods;
      },
      business() {
        return this.$store.getters.getLoginAuthBusiness;
      },
    },
  };
</script>
