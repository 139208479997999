import resource from '../../resource';

const state = {
  groups: null,
};

const getters = {
  getGroups(state) {
    return state.groups;
  },
};

const mutations = {
  SET_GROUPS(state, data) {
    state.groups = data;
  },
};

const actions = {
  fetchGroups(context, { business }) {
    return resource.get(`/businesses/${business}/groups`)
      .then((response) => {
        context.commit('SET_GROUPS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  fetchGroup(context, { business, group }) {
    return resource.get(`/businesses/${business}/groups/${group}`)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  searchGroups(context, { business, search }) {
    return resource.get(`/businesses/${business}/groups`, { params: { name: search } })
      .then((response) => {
        context.commit('SET_GROUPS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  toGroupsPage(context, { business, search, page }) {
    return resource.get(`/businesses/${business}/groups`, { params: { name: search, page: page } })
      .then((response) => {
        context.commit('SET_GROUPS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  createGroup(context, { business, payload }) {
    return resource.post(`/businesses/${business}/groups`, payload)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  updateGroup(context, { business, group, payload }) {
    return resource.put(`/businesses/${business}/groups/${group}`, payload)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  deleteGroup(context, { business, group }) {
    return resource.delete(`/businesses/${business}/groups/${group}`)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
