import resource from "@/resource";

const state = {
  order: null
};

const getters = {
  getPrintOrder(state) {
    return state.order;
  },
};

const mutations = {
  SET_PRINT_ORDER(state, order) {
    state.order = order;
  },
};

const actions = {
  fetchPrintOrder(context, { business, order }) {
    return resource.get(`/businesses/${business}/orders/${order}`)
        .then((response) => {
          context.commit('SET_PRINT_ORDER', response.data);
          return Promise.resolve(response);
        }).catch(function (error) {
          return Promise.reject(error);
        });
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};