import AProfile from './index'

export default [
  {
    path: '/profile',
    name: 'profile',
    component: AProfile,
    meta: {
      requiresAuth: true
    }
  },
];

