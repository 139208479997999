<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat dark color="primary">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title v-if="creating">Criar Seleção</v-list-item-title>
            <v-list-item-title v-if="updating">Alterar Seleção</v-list-item-title>
            <v-list-item-subtitle>Listagem dos dados</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn @click="$router.back()" icon>
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-col>
    <template v-if="payload">
      <v-col cols="12">
        <v-card flat outlined>
          <v-card-text>
            <v-form ref="form" v-model="valid">
              <v-row dense>
                <v-col cols="12">
                  <v-text-field v-model="payload.name" label="Nome*" type="text"
                                :error-messages="formErrors.name"
                                hide-details="auto" :rules="[$rules.required]"/>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="payload.amount" prefix="R$" label="Valor*" type="number"
                                hide-details="auto" :rules="[$rules.required]"/>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="payload.calories" label="Calorias" type="number"
                                hide-details="auto"/>
                </v-col>
                <v-col cols="12">
                  <v-textarea auto-grow rows="1" v-model="payload.description" label="Descrição*"
                              hide-details="auto" :rules="[$rules.required]"/>
                </v-col>
                <v-col cols="12">
                  <v-switch flat inset v-model="payload.active" label="Visível na Loja"
                            :error-messages="formErrors.active"
                            hide-details="auto"/>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn text @click="$refs.form.reset()">Limpar</v-btn>
            <v-btn depressed color="primary" @click="storeSelection()" v-if="creating">Salvar</v-btn>
            <v-btn depressed color="primary" @click="updateSelection()" v-if="updating">Alterar</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  export default {
    name: 'a-selection-form',
    data: () => ({
      formErrors: {},
      payload: {
        name: '',
        amount: '',
        calories: '',
        description: '',
        active: true,
      },
      valid: true,
    }),
    created() {
      if (this.updating) {
        this.$store.dispatch('fetchSelection', {
          business: this.business.id,
          item: this.$route.params.item,
          option: this.$route.params.option,
          selection: this.$route.params.selection
        }).then((response) => {
          this.payload = {
            name: response.data.data.name,
            amount: response.data.data.amount,
            calories: response.data.data.calories,
            description: response.data.data.description,
            active: response.data.data.active,
          }
        });
      }
    },
    methods: {
      storeSelection() {
        if (!this.$refs.form.validate()) {
          alert('Verifique os campos do formulário!');
          return;
        }
        this.$store.dispatch('createSelection', {
          business: this.business.id,
          item: this.$route.params.item,
          option: this.$route.params.option,
          payload: this.payload
        }).then(() => {
          this.$refs.form.reset();
          this.$router.back();
        }).catch((error) => {
          if (error.response && error.response.status === 422) {
            this.formErrors = error.response.data.errors;
          }
        });
      },
      updateSelection() {
        if (!this.$refs.form.validate()) {
          alert('Verifique os campos do formulário!');
          return;
        }
        this.$store.dispatch('updateSelection', {
          business: this.business.id,
          item: this.$route.params.item,
          option: this.$route.params.option,
          selection: this.$route.params.selection,
          payload: this.payload
        }).then(() => {
          this.$refs.form.reset();
          this.$router.back();
        }).catch((error) => {
          if (error.response && error.response.status === 422) {
            this.formErrors = error.response.data.errors;
          }
        });
      },
    },
    computed: {
      creating() {
        return this.$route.name === 'selections_create';
      },
      updating() {
       return this.$route.name === 'selections_update';
      },
      business() {
        return this.$store.getters.getLoginAuthBusiness;
      },
    },
  };
</script>
