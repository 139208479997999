<template>
  <v-row dense align-content="center" justify="center" class="fill-height">
    <v-col cols="12" class="text-center" v-if="payload">
      <v-avatar tile size="100">
        <img :src="require('../../assets/logo.png')" alt="Autabit">
      </v-avatar>
      <h3 class="text-h3 font-weight-light" v-if="payload.name">{{ payload.name }}</h3>
      <h6 class="text-h6 font-weight-light" v-if="payload.name">Você está realizando login em {{ payload.name }}!</h6>
      <v-btn depressed class="mt-5" @click="login">Continuar</v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'a-third-party-login',
  data: () => ({
    payload: null,
  }),
  created() {
    this.payload = this.$route.query;
  },
  methods: {
    login() {
      this.$store.dispatch('loginRemoveBusiness');
      this.$store.dispatch('loginRemoveToken');
      this.$store.dispatch('loginSetToken', this.payload.access_token);
      this.$store.dispatch('loginFetchAuthBusiness').then(() => {
        this.$router.push({ name: 'dashboard' });
      });
    }
  },
};
</script>
