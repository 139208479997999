import AStockList from './index'
import AStockForm from './form'

export default [
  {
    path: '/items/:item/stocks/',
    component: AStockList,
    name: 'stocks',
    meta: {
     requiresAuth: true
    }
  },
  {
    path: '/items/:item/stocks/create',
    component: AStockForm,
    name: 'stocks_create',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/items/:item/stocks/:stock/update',
    component: AStockForm,
    name: 'stocks_update',
    meta: {
      requiresAuth: true
    }
  },
];
