<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat dark color="primary">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title>Avaliação</v-list-item-title>
            <v-list-item-subtitle>Listagem dos dados</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn disabled icon @click="$router.push({ name: 'ratings_create' })">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-col>
    <template v-if="ratings">
      <v-col cols="12">
        <v-text-field v-model="ratingsSearch" prepend-inner-icon="mdi-magnify"
                      label="Pesquisar" @keyup.native.enter="searchRatings()"/>
      </v-col>
      <v-col cols="12">
        <v-data-table
                    :headers="ratingsHeaders"
                    :items="ratings.data"
                    :page.sync="ratings.meta.current_page"
                    :items-per-page.sync="ratings.meta.per_page"
                    hide-default-footer
                    disable-sort>
          <template v-slot:item.rating="{ item }">
            <v-rating dense readonly background-color="gray lighten-1" half-increments v-model="item.rating"/>
          </template>
          <template v-slot:item.moderation="{ item }">
            <v-simple-checkbox v-model="item.moderation" disabled></v-simple-checkbox>
          </template>
          <template v-slot:item.moderation_reason="{ item }">
            {{ item.moderation ? ratingModerationReason(item.moderation_reason) : '' }}
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn :to="{ name: 'ratings_update', params: { rating: item.id } }" icon class="mr-0">
              <v-icon>mdi-file-document-edit-outline</v-icon>
            </v-btn>
            <v-btn @click="destroyRating(item)" icon class="mr-0">
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12">
        <v-pagination v-model="page" :length="ratings.meta.last_page" :total-visible="7"></v-pagination>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  export default {
    name: 'a-rating-list',
    data: () => ({
      page: 1,
      ratingsSearch: '',
      ratingsHeaders: [
        { text: 'Avaliação', value: 'rating' },
        { text: 'Comentário', value: 'comment' },
        { text: 'Resposta', value: 'replica' },
        { text: 'Em Moderação', align: 'center', value: 'moderation' },
        { text: 'Motivo da Moderação', value: 'moderation_reason' },
        { text: 'Ação', align: 'center', width: 120, value: 'action' },
      ],
    }),
    watch: {
      page(value) {
        this.$store.dispatch('toRatingsPage', {
          business: this.business.id,
          search: this.ratingsSearch,
          page: value
        });
      },
    },
    created() {
      this.fetchRatings();
    },
    methods: {
      fetchRatings() {
        this.$store.dispatch('fetchRatings', {
          business: this.business.id,
        });
      },
      searchRatings() {
        this.$store.dispatch('searchRatings', {
          business: this.business.id,
          search: this.ratingsSearch
        });
      },
      destroyRating(rating) {
        if (confirm('Tem certeza que deseja excluir esse item?')) {
          this.$store.dispatch('deleteRating', {
            business: this.business.id,
            rating: rating.id
          }).then(() => {
            this.fetchRatings();
          });
        }
      },
      ratingModerationReason(reason){
        switch (reason) {
          case 'INAPPROPRIATE_LANGUAGE':
            return 'Linguágem Inapropriada';
          case 'FAKE_CONTENT':
            return 'Conteúdo Falso ou Spam';
          case 'WRONG_ASSESSMENT':
            return 'Avaliação Equivocada';
          case 'APP_COMPLAINT':
            return 'Reclamação Sobre o Aplicativo';
          case 'AGREEMENT_TO_CLIENT':
            return 'Concordância Com o Cliente';
          default:
            return 'Indefinido';
        }
      },
    },
    computed: {
      ratings() {
        return this.$store.getters.getRatings;
      },
      business() {
        return this.$store.getters.getLoginAuthBusiness;
      },
    },
  };
</script>
