<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat dark color="primary">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title>Imagem</v-list-item-title>
            <v-list-item-subtitle>Listagem dos dados</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click="$router.push({ name: 'thumbnails_create' })">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-col>
    <template v-if="thumbnails">
      <v-col cols="12">
        <v-text-field v-model="thumbnailsSearch" prepend-inner-icon="mdi-magnify"
                      label="Pesquisar" @keyup.native.enter="searchThumbnails()"/>
      </v-col>
      <v-col cols="12">
        <v-data-iterator
            :items="thumbnails.data"
            :page.sync="thumbnails.meta.current_page"
            :items-per-page.sync="thumbnails.meta.per_page"
            hide-default-footer
            disable-sort>
          <template v-slot:default="props">
            <v-row dense>
              <v-col cols="6" md="3" lg="2" xl="1" v-for="item in props.items" :key="item.id">
                <v-card flat outlined>
                  <v-img :src="item.url" :aspect-ratio="1" contain/>
                  <v-card-actions>
                  <span class="d-inline-block text-truncate">
                    {{ item.name }}
                  </span>
                    <v-spacer/>
                    <v-btn :to="{ name: 'thumbnails_update', params: { thumbnail: item.id } }" icon class="mr-0">
                      <v-icon color="primary">mdi-file-document-edit-outline</v-icon>
                    </v-btn>
                    <v-btn @click="destroyThumbnail(item)" icon class="mr-0">
                      <v-icon color="primary">mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-data-iterator>
      </v-col>
      <v-col cols="12">
        <v-pagination v-model="page" :length="thumbnails.meta.last_page" :total-visible="7"></v-pagination>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  export default {
    name: 'a-thumbnail-list',
    data: () => ({
      page: 1,
      thumbnailsSearch: '',
    }),
    watch: {
      page(value) {
        this.$store.dispatch('toThumbnailsPage', {
          business: this.business.id,
          search: this.thumbnailsSearch,
          page: value
        });
      },
    },
    created() {
      this.fetchThumbnails();
    },
    methods: {
      fetchThumbnails() {
        this.$store.dispatch('fetchThumbnails', {
          business: this.business.id,
        });
      },
      searchThumbnails() {
        this.$store.dispatch('searchThumbnails', {
          business: this.business.id,
          search: this.thumbnailsSearch
        });
      },
      destroyThumbnail(thumbnail) {
        if (confirm('Tem certeza que deseja excluir esse item?')) {
          this.$store.dispatch('deleteThumbnail', {
            business: this.business.id,
            thumbnail: thumbnail.id
          }).then(() => {
            this.fetchThumbnails();
          });
        }
      },
    },
    computed: {
      thumbnails() {
        return this.$store.getters.getThumbnails;
      },
      business() {
        return this.$store.getters.getLoginAuthBusiness;
      },
    },
  };
</script>
