<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat dark color="primary">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title>Estoque</v-list-item-title>
            <v-list-item-subtitle>Listagem dos dados</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click="$router.push({ name: 'stocks_create' })">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-col>
    <template v-if="stocks">
      <v-col cols="12">
        <v-text-field v-model="stocksSearch" prepend-inner-icon="mdi-magnify"
                      label="Pesquisar" @keyup.native.enter="searchStocks()"/>
      </v-col>
      <v-col cols="12">
        <v-data-table
                    :headers="stocksHeaders"
                    :items="stocks.data"
                    :page.sync="stocks.meta.current_page"
                    :items-per-page.sync="stocks.meta.per_page"
                    hide-default-footer
                    disable-sort>
          <template v-slot:item.type="{ item }">
            <span :style="{color: item.type === 'INCREASE' ? 'green' : 'red'}">{{ stockType(item.type) }}</span>
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn disabled :to="{ name: 'stocks_update', params: { stock: item.id } }" icon class="mr-0">
              <v-icon>mdi-file-document-edit-outline</v-icon>
            </v-btn>
            <v-btn disabled @click="destroyStock(item)" icon class="mr-0">
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12">
        <v-pagination v-model="page" :length="stocks.meta.last_page" :total-visible="7"></v-pagination>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  export default {
    name: 'a-stock-list',
    data: () => ({
      page: 1,
      stocksSearch: '',
      stocksHeaders: [
        { text: 'Ação', value: 'type' },
        { text: 'Quantidade', value: 'quantity' },
        { text: 'Descrição', value: 'description' },
        { text: 'Ação', align: 'center', width: 120, value: 'action' },
      ],
    }),
    watch: {
      page(value) {
        this.$store.dispatch('toStocksPage', {
          business: this.business.id,
          item: this.$route.params.item,
          search: this.stocksSearch,
          page: value
        });
      },
    },
    created() {
      this.fetchStocks();
    },
    methods: {
      fetchStocks() {
        this.$store.dispatch('fetchStocks', {
          business: this.business.id,
          item: this.$route.params.item,
        });
      },
      searchStocks() {
        this.$store.dispatch('searchStocks', {
          business: this.business.id,
          item: this.$route.params.item,
          search: this.stocksSearch
        });
      },
      destroyStock(stock) {
        if (confirm('Tem certeza que deseja excluir esse item?')) {
          this.$store.dispatch('deleteStock', {
            business: this.business.id,
            item: this.$route.params.item,
            stock: stock.id
          }).then(() => {
            this.fetchStocks();
          });
        }
      },
      stockType(type){
        switch (type) {
          case 'INCREASE':
            return 'Entrada';
          case 'DECREASE':
            return 'Saída';
          default:
            return 'Indefinido';
        }
      },
    },
    computed: {
      stocks() {
        return this.$store.getters.getStocks;
      },
      business() {
        return this.$store.getters.getLoginAuthBusiness;
      },
    },
  };
</script>
