<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat dark color="primary">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title>Item</v-list-item-title>
            <v-list-item-subtitle>Listagem dos dados</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click="$router.push({ name: 'items_create' })">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-col>
    <template v-if="items">
      <v-col cols="12">
        <v-text-field v-model="itemsSearch" prepend-inner-icon="mdi-magnify"
                      label="Pesquisar" @keyup.native.enter="searchItems()"/>
      </v-col>
      <v-col cols="12">
        <v-data-table
                    :headers="itemsHeaders"
                    :items="items.data"
                    :page.sync="items.meta.current_page"
                    :items-per-page.sync="items.meta.per_page"
                    hide-default-footer
                    disable-sort>
          <template v-slot:item.thumbnail="{ item }">
            <v-avatar rounded width="48" height="48">
              <v-img :src="item.thumbnail ? item.thumbnail.url : require('../../assets/placeholder.png')" aspect-ratio="1"/>
            </v-avatar>
          </template>
          <template v-slot:item.amount="{ item }">
            <template v-if="item.options.length > 0">
              <span>A partir de {{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.min_amount) }}</span>
            </template>
            <template v-else>
              <span :style="item.promotion ? {'text-decoration': 'line-through'} : ''"> {{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.amount) }}</span>
              <span v-if="item.promotion"> - {{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.promotion_amount) }}</span>
            </template>
          </template>
          <template v-slot:item.active="{ item }">
            <v-simple-checkbox v-model="item.active" disabled/>
          </template>
          <template v-slot:item.featured="{ item }">
            <v-simple-checkbox v-model="item.featured" disabled/>
          </template>
          <template v-slot:item.stock="{ item }">
            <template v-if="item.manage_stock">{{ item.stock }}</template>
            <template v-else>Desabilitado</template>
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn :to="{ name: 'items_update', params: { item: item.id } }" icon class="mr-0">
              <v-icon>mdi-file-document-edit-outline</v-icon>
            </v-btn>
            <v-btn :to="{ name: 'options', params: { item: item.id } }" icon class="mr-0">
              <v-icon>mdi-format-list-checkbox</v-icon>
            </v-btn>
            <v-btn :disabled="!item.manage_stock" :to="{ name: 'stocks', params: { item: item.id } }" icon class="mr-0">
              <v-icon>mdi-archive</v-icon>
            </v-btn>
            <v-btn @click="destroyItem(item)" icon class="mr-0">
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12">
        <v-pagination v-model="page" :length="items.meta.last_page" :total-visible="7"></v-pagination>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  export default {
    name: 'a-item-list',
    data: () => ({
      page: 1,
      itemsSearch: '',
      itemsHeaders: [
        { text: 'Thumbnail', align: 'center', width: 48, value: 'thumbnail' },
        { text: 'Nome', value: 'name' },
        { text: 'Código Interno', value: 'sku' },
        { text: 'Preço', value: 'amount' },
        { text: 'Visível', value: 'active' },
        { text: 'Destaque', value: 'featured' },
        { text: 'Estoque', value: 'stock' },
        { text: 'Ação', align: 'center', width: 240, value: 'action' },
      ],
    }),
    watch: {
      page(value) {
        this.$store.dispatch('toItemsPage', {
          business: this.business.id,
          search: this.itemsSearch,
          page: value
        });
      },
    },
    created() {
      this.fetchItems();
    },
    methods: {
      fetchItems() {
        this.$store.dispatch('fetchItems', {
          business: this.business.id,
        });
      },
      searchItems() {
        this.$store.dispatch('searchItems', {
          business: this.business.id,
          search: this.itemsSearch
        });
      },
      destroyItem(item) {
        if (confirm('Tem certeza que deseja excluir esse item?')) {
          this.$store.dispatch('deleteItem', {
            business: this.business.id,
            item: item.id
          }).then(() => {
            this.fetchItems();
          });
        }
      },
    },
    computed: {
      items() {
        return this.$store.getters.getItems;
      },
      business() {
        return this.$store.getters.getLoginAuthBusiness;
      },
    },
  };
</script>
