<template>
  <v-row dense v-touch="{ down: () => pullToRefresh() }" align-content="start" class="fill-height">
    <!--Online-->
    <v-col cols="12">
      <a-profile-online-switch/>
    </v-col>
    <!--Services-->
    <template v-if="business">
      <v-col cols="12" class="mt-5">
        <h1 class="text-subtitle-1 font-weight-light text-uppercase">Pedidos Categorizados</h1>
      </v-col>
      <v-col cols="12" md="4">
        <v-card flat :to="{ name: 'orders', query: { service: 'DELIVERY' } }" :disabled="!business.enable_delivery">
          <v-img :src="require('../../assets/banner_delivery.png')"/>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card flat :to="{ name: 'orders', query: { service: 'PICK_UP' } }" :disabled="!business.enable_pick_up">
          <v-img :src="require('../../assets/banner_pickup.png')"/>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card flat :to="{ name: 'orders', query: { service: 'DINE_IN' } }" :disabled="!business.enable_dine_in">
          <v-img :src="require('../../assets/banner_dinein.png')"/>
        </v-card>
      </v-col>
    </template>
    <!--Orders-->
    <template v-if="orders">
      <v-col cols="12" class="mt-5">
        <h1 class="text-subtitle-1 font-weight-light text-uppercase">Pedidos Pendentes</h1>
      </v-col>
      <template v-if="orders.data.length">
        <v-col cols="12" md="6" lg="4" xl="3" v-for="order in orders.data" :key="order.id">
          <a-order-tile :order="order"/>
        </v-col>
      </template>
      <template v-else>

      </template>
    </template>
    <!--Waiters-->
    <v-col cols="12" class="mt-5">
      <a-business-waiters-widget/>
    </v-col>
    <!--Drivers-->
    <v-col cols="12" class="mt-5">
      <a-business-drivers-widget/>
    </v-col>
  </v-row>
</template>

<script>
import AProfileOnlineSwitch from "@/components/Profile/AProfileOnlineSwitch";
import ABusinessWaitersWidget from "@/components/Dashboard/ABusinessWaitersWidget";
import ABusinessDriversWidget from "@/components/Dashboard/ABusinessDriversWidget";
import AOrderTile from "@/components/AOrderTile";

export default {
  name: 'a-home',
  components: {AOrderTile, ABusinessDriversWidget, ABusinessWaitersWidget, AProfileOnlineSwitch},
  data: () => ({
    orders: null,
    polling: null,
  }),
  created () {
    this.fetchOrders();
    this.pollData();
  },
  beforeDestroy () {
    clearInterval(this.polling);
  },
  methods: {
    pollData () {
      this.polling = setInterval(() => {
        this.fetchOrders();
      }, 60000);
    },
    fetchOrders() {
      this.$axios.get(`/businesses/${this.business.id}/orders`, { params: { status: ['PENDING'] }, loader: false })
        .then((response) => {
          this.orders = response.data;
          if (response.data.data.length) {
            this.$store.dispatch('setShowOrderDialogStatus', false);
            this.$store.dispatch('setOrderDialogPayload', response.data.data[0]);
            this.$store.dispatch('setShowOrderDialogStatus', true);
            this.$audio.play();
          }
        });
    },
    pullToRefresh() {
      if(document.scrollingElement.scrollTop === 0) {
        this.fetchOrders();
      }
    },
  },
  computed: {
    business() {
      return this.$store.getters.getLoginAuthBusiness;
    }
  }
};
</script>
