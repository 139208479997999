import resource from '../../resource';

const state = {
  ratings: null,
};

const getters = {
  getRatings(state) {
    return state.ratings;
  },
};

const mutations = {
  SET_RATINGS(state, data) {
    state.ratings = data;
  },
};

const actions = {
  fetchRatings(context, { business }) {
    return resource.get(`/businesses/${business}/ratings`)
      .then((response) => {
        context.commit('SET_RATINGS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  fetchRating(context, { business, rating }) {
    return resource.get(`/businesses/${business}/ratings/${rating}`)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  searchRatings(context, { business, search }) {
    return resource.get(`/businesses/${business}/ratings`, { params: { name: search } })
      .then((response) => {
        context.commit('SET_RATINGS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  toRatingsPage(context, { business, search, page }) {
    return resource.get(`/businesses/${business}/ratings`, { params: { name: search, page: page } })
      .then((response) => {
        context.commit('SET_RATINGS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  createRating(context, { business, payload }) {
    return resource.post(`/businesses/${business}/ratings`, payload)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  updateRating(context, { business, rating, payload }) {
    return resource.put(`/businesses/${business}/ratings/${rating}`, payload)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  deleteRating(context, { business, rating }) {
    return resource.delete(`/businesses/${business}/ratings/${rating}`)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
