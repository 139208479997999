import ARatingList from './index'
import ARatingForm from './form'

export default [
  {
    path: '/ratings/',
    component: ARatingList,
    name: 'ratings',
    meta: {
     requiresAuth: true
    }
  },
  {
    path: '/ratings/create',
    component: ARatingForm,
    name: 'ratings_create',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/ratings/:rating/update',
    component: ARatingForm,
    name: 'ratings_update',
    meta: {
      requiresAuth: true
    }
  },
];
